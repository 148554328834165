import { Button, Grid, Typography } from '@mui/material';
import Link from 'next/link';

interface Props {
  showFaq?: boolean
  email: string
}

export function Contact({showFaq = false, email}: Props) {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h1" component="h1">
          Kontakt
        </Typography>
      </Grid>
      {showFaq && <Grid item xs={12}>
        <h3>Häufig gestellte Fragen</h3>
        <p>
          Solltest du Fragen zu unserer Plattform haben so schau bitte zuerst in
          unserem <Link href="/faq">FAQ Bereich</Link>. Hier findest du
          Hilfestellungen zu den häufigsten Fragen rund um getasugar. Die FAQ
          werden von uns laufend aktualisiert und erweitert.
        </p>

        <Button
          color="primary"
          variant="contained"
          size="small"
          href="/faq"
        >
          Zu den FAQ
        </Button>
      </Grid>}

      <Grid item xs={12} style={{ paddingTop: '1em' }}>
        <h3>Support per E-Mail</h3>
        <p>
          Falls du in den FAQ nicht fündig geworden bist oder ein anderes
          Anliegen hast, sende uns bitte eine E-Mail.
        </p>
        <p>
          Du erreichst unser Support-Team unter folgender Adresse:{' '}
          <Link href={"mailto:" + email}>{email}</Link>
        </p>
      </Grid>
    </Grid>
  );
}
