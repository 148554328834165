'use client'
import { styled } from "@mui/material";
import { CSSProperties, PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  backgroundImage?: string
  backgroundStyles?: CSSProperties
  containerStyles?: CSSProperties
}

interface StyleProps {
  ownerState: {
    image?: string
    backgroundStyles?: CSSProperties
    containerStyles?: CSSProperties
  };
}

const Background = styled('div', {
  name: 'Background', // The component name
  slot: 'background', // The slot name
})<StyleProps>(({ theme, ownerState }) => ({
  backgroundColor: "rgb(245, 245, 245)",
  backgroundSize: "cover",
  backgroundPosition: "bottom center",
  paddingBottom: 0,
  backgroundImage: ownerState.image? 'url(' + ownerState.image + ')' : undefined,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  ...ownerState.backgroundStyles,
}));

const MainContainer = styled('div', {
  name: 'MainContainer', // The component name
  slot: 'root', // The slot name
})<StyleProps>(({ theme, ownerState }) => ({
  paddingRight: '15px',
  paddingLeft: '15px',
  marginRight: 'auto',
  marginLeft: 'auto',
  width: '100%',
  ...ownerState.containerStyles,
  [theme.breakpoints.up('sm')]: {
    maxWidth: '540px',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '720px',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '960px',
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: '1140px',
  },
}));

export function Container(props: Props) {
  const { children, backgroundImage, backgroundStyles = {}, containerStyles = {} } = props

  const ownerState = {
    image: backgroundImage,
    containerStyles,
    backgroundStyles
  }

  return (
    <Background ownerState={ownerState}>
      <MainContainer ownerState={ownerState}>{children}</MainContainer>
    </Background>
  )
}