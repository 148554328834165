
// @mui/material components
import { makeStyles, createStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

// @mui/icons-material
import { ExploreOutlined, People, Favorite } from '@mui/icons-material'
import {KissIcon} from '../icons/KissIcon'
import Badge from '@mui/material/Badge'

import {styles} from '../styles/common'

const useStyles = makeStyles(() =>
  createStyles({
    ...styles,
  })
)

interface Props {
  hideNavItems?: boolean
  onClick(): void
}

export default function HeaderLinks(props: Props) {
  const classes = useStyles()
  const { hideNavItems = false, onClick } = props


  const iconNavItems = [
    {
      label: 'Entdecken',
      route: () => ({
        pathname: "/discover",
        state: {
          searchArgs: {
            seed: Math.random()
          }
        }
      }),
      icon: <ExploreOutlined />,
      display: { xs: 'none', sm: 'none', md: 'block' },
      // Note: count is always 0 (Meeting 30.09.22)
      count: 0,
    },
    {
      label: 'Besucher',
      path: '/visits',
      icon: <People />,
      display: { xs: 'block', sm: 'block' },
      count: 0,
    },
    {
      label: 'Küsse',
      path: '/kisses',
      icon: <KissIcon />,
      display: { xs: 'block', sm: 'block' },

      count: 0,
    },
    {
      label: 'Favoriten',
      path: '/favorites',
      display: { xs: 'block', sm: 'block' },
      icon: <Favorite />,
      count: 0,
    },
  ]

  return (
    <Grid container spacing={1} alignItems="center" alignContent="space-between" direction="row">
      {!hideNavItems &&
        iconNavItems.map((item: any, key: number) => {
          return (
            <Box
              component={Grid}
              display={item.display}
              key={key}
              style={{ borderBottom: '2px solid transparent', width: '4.2em', cursor: 'pointer' }}
              onClick={() => onClick()}
            >
              <span className={classes.isWhite}>
                <div className={classes.textCenter}>
                  <Badge
                    badgeContent={item.count}
                    color="secondary"
                    invisible={item.count === 0}
                    max={99}
                  >
                    {item.icon}
                  </Badge>

                  <div style={{ fontSize: '0.75em' }}>{item.label}</div>
                </div>
              </span>
            </Box>
          )
        })}
      <Box component={Grid} key={'profile'} display={{ xs: 'none', sm: 'none', md: 'inline' }}>
      </Box>
    </Grid>
  )
}
