'use client'
import classNames from "classnames";
import { makeStyles } from "@mui/styles";

// @mui/material components
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import LockIcon from "@mui/icons-material/Lock";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

//styles
import badgeStyle from "../styles/badgeStyle";
import {BoundingContainer, PrimaryContainer, styles} from "../styles/common";

import { Grid } from "@mui/material";

const useStyles = makeStyles({
  ...badgeStyle,
  ...styles,
  isOverline: {
    opacity: "0.5",
  },
  isNarrow: {
    marginTop: "5px !important",
  },
  hasTextShadow: {
    textShadow: "0px 0px 10px 0px rgba(0,0,0,0.15)",
  },
});

export function USPBlock() {
  const classes = useStyles();

  const iconStyle = { fontSize: 60 };
  const iconClasses = classNames(classes.hasTextShadow);

  const trendingIcon = (
    <TrendingUpIcon
      style={iconStyle}
      className={iconClasses}
    />
  );

  const userIcon = (
    <VerifiedUserIcon
      style={iconStyle}
      className={iconClasses}
    />
  );

  const lockIcon = (
    <LockIcon
      style={iconStyle}
      className={iconClasses}
    />
  );

  const usps = [
    {
      headline: "Wachsende Community",
      subline: "Viele Registrierungen täglich und viele aktive Mitglieder",
      icon: trendingIcon,
    },
    {
      headline: "Kompetent & Qualifiziert",
      subline: "Die wohl beste Plattform für Frauen & Männer",
      icon: userIcon,
    },
    {
      headline: "Grösstmögliche Sicherheit",
      subline: "Serverstandort und Datenschutz in Deutschland",
      icon: lockIcon,
    },
  ];

  return (
    <PrimaryContainer>
      <BoundingContainer>
        <Grid container
          alignItems="stretch"
          justifyContent="space-between"
          direction="row"
        >
          {usps.map((usp, idx) => (
            <Grid item xs={12} sm={4} key={idx}>
              <Grid container alignItems="center">
                <Grid item xs={3}>{usp.icon}</Grid>
                <Grid item xs={9}>
                  <h6 className={classNames(classes.isOverline)}>
                    {usp.headline}
                  </h6>
                  <h3 className={classNames(classes.isNarrow, classes.isBold)}>
                    {usp.subline}
                  </h3>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </BoundingContainer>
    </PrimaryContainer>
  );
}
