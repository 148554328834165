import { createStyles } from "@mui/styles";
import { defaultFont } from "./base";

export const styles = createStyles({
  formControl: {
    margin: "0 0 17px 0",
    paddingTop: "5px",
    position: "relative",
    "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
      color: "#495057",
    },
  },
	input: {
    color: "#495057",
    height: "unset",
    "&,&::placeholder": {
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "1.42857",
      opacity: "1",
    },
    "&::placeholder": {
      color: "#AAAAAA",
    },
  },
  labelRoot: {
    ...defaultFont,
    color: "#AAAAAA !important",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "1.42857",
    top: "10px",
    letterSpacing: "unset",
    "& + $underline": {
      marginTop: "0px",
    },
  },
})