'use client'
import { forwardRef } from "react";
import classNames from "classnames";

// @mui/material components
import makeStyles from "@mui/styles/makeStyles";
import { Button as MuiButton, ButtonProps } from "@mui/material";

// core components

import buttonStyle from "../styles/buttonStyle";

const makeComponentStyles = makeStyles(() => ({
  ...buttonStyle
}));

interface Props extends Partial<ButtonProps> {
  simple?: boolean,
  round?: boolean,
  fullWidth?: boolean,
  disabled?: boolean,
  block?: boolean,
  link?: boolean,
  justIcon?: boolean,
  className?: string
  children: React.ReactElement | string
  onClick?(): void
}

export const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    color,
    round,
    children,
    fullWidth,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    ...rest
  } = props;

  const classes = makeComponentStyles();

  const classNameObject: any = {
    [classes.button]: true,
    // [classes[size]]: size,
    // [classes[color]]: color,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    // [className]: className
  }

  if(size) {
    // classNameObject[classes[size]] = true
  }

  const btnClasses = classNames(classNameObject);
  return (
    <MuiButton {...rest} ref={ref} classes={{ root: btnClasses }}>
      {children}
    </MuiButton>
  );
});
