
export const blackColor = "#333"
export const greyIconColor = "#495057"
export const primaryColor = "#542687";
export const secondaryColor = "#fafafa";
export const primaryColor50 = "rgba(84,38,135,0.10)";
export const grayColor = "#999999";
export const whiteColor = "#FFF";

export const primaryColorArray = [
  '#542687',
  '#380176',
  '#AF9DC3',
  '#4F0050',
  '#744BA1',
  '#7A5A9D',
  'rgba(84,38,135,0.3)',
]