'use client'
import { ProfileType } from '@sugar/models';
import { FormEvent, useEffect, useState } from 'react';
import { Location } from '@sugar/models'
import dayjs, { Dayjs } from 'dayjs'

const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
const userNameRegEx = new RegExp(/[^A-Za-zäÄöÖüÜ0-9._\s-]+/);
const shouldNotContainRegEx = new RegExp(/[\s-.]{2,}/);
const firstElShouldBeLetter = new RegExp(/^[A-Za-zäÄöÖüÜ]+/);

interface ValueWithMessage {
  value: string,
  valid: boolean,
  errorMsg?: string
}

export const useRegister = () => {
  const params = new URLSearchParams(window.location.search);
  const maxDate = dayjs().subtract(18, 'years') //  subYears(new Date(), 18);
  const minDate = dayjs().subtract(120, 'years') // subYears(new Date(), 120);
  const startDate = dayjs().subtract(26, 'years') // new Date(Date.now() - 1000 * 3600 * 24 * 365 * 26);

  const [userName, setUserName] = useState<ValueWithMessage>({
    value: '',
    valid: true,
    errorMsg: undefined,
  });
  const [email, setEmail] = useState<ValueWithMessage>({
    value: '',
    valid: true,
    errorMsg: undefined,
  });
  const [password, setPassword] = useState<ValueWithMessage>({
    value: '',
    valid: true,
    errorMsg: undefined,
  });

  const [location, setLocation] = useState<Location | null>(null);
  const [locationValid, setLocationValid] = useState(true);

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedDateError, setSelectedDateError] = useState<any>(null)

  const [accepted, setAccepted] = useState(false);
  const [acceptedError, setAcceptedError] = useState('');
  const [error, setError] = useState('');

  const [type, setType] = useState<ProfileType | ''>(
    (params.get('type') as ProfileType) || ''
  );
  const [typeValid, setTypeValid] = useState(true);

  const [isRegistering, setIsRegistering] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState(false);

  useEffect(() => {
    const windowRef = window as any
    
    windowRef._mtm = windowRef._mtm || [];
    windowRef._mtm.push({'event': 'funnel_reg_form'});

    windowRef.dataLayer = windowRef.dataLayer || [];
    windowRef.dataLayer.push({'event': 'funnel_reg_form'});
  }, []);

  useEffect(() => {
    if (registerSuccess) {
      window.scrollTo({
        top: 0,
        behavior: "auto",
      });
    }
  }, [registerSuccess]);

  function validateUserName(name: string) {
    let errorMsg =
      "Dein Name muss mindestens 3 Zeichen und maximal 16 Zeichen lang sein";
    let isValid = name !== "" && (name.length >= 3 && name.length <= 16);

    if(!isValid) {
      setUserName({
        value: name,
        valid: isValid,
        errorMsg: !isValid ? errorMsg : undefined,
      });
      return isValid
    }

    if (isValid) {
      const match = userNameRegEx.exec(name);
      isValid = match === null;
      if (!isValid) {
        errorMsg =
          "Erlaubte Zeichen sind Buchstaben, Zahlen, Leerzeichen sowie ,-_";
      }
    }
    if (isValid) {
      const match = shouldNotContainRegEx.exec(name);
      isValid = match === null;
      if (!isValid) {
        errorMsg = "Sonderzeichen dürfen nicht direkt hintereinander sein";
      }
    }
    if (isValid) {
      const match = firstElShouldBeLetter.exec(name);
      isValid = match !== null;
      if (!isValid) {
        errorMsg = "Der Name muss mit einem Buchstaben beginnen";
      }
    }
    setUserName({
      value: name,
      valid: isValid,
      errorMsg: !isValid ? errorMsg : undefined,
    });

    return isValid
  }

  function validateEmail(email: string) {
    const isValid = email !== "" && emailRegex.test(email);
    setEmail({
      value: email,
      valid: isValid,
      errorMsg: !isValid
        ? "Bitte eine gültige Email-Adresse eingeben"
        : undefined,
    });

    return isValid
  }

  function validatePassword(pwd: string) {
    const isValid = pwd !== "" && pwd.length >= 5;
    setPassword({
      value: pwd,
      valid: isValid,
      errorMsg: !isValid
        ? "Dein Passwort muss mindestens 5 Zeichen lang sein"
        : undefined,
    });

    return isValid
  }

  function checkRequirements() {
    return (
      userName.value !== "" &&
      userName.valid &&
      email.value !== "" &&
      email.valid &&
      password.value !== "" &&
      password.valid &&
      location !== null &&
      selectedDate !== null &&
      selectedDate.isValid() &&
      selectedDate.isBefore(maxDate) &&
      selectedDate.isAfter(minDate) &&
      accepted
    );
  }

  async function handleSubmit(event?: FormEvent) {
    let shouldAbort = false
    if(event) {
      event.preventDefault();
    }
    if (type === '') {
      shouldAbort = true
      setTypeValid(false);
    }

    if (!location) {
      setLocationValid(false);
      shouldAbort = true
    }

    if(!validateUserName(userName.value)) {
      shouldAbort = true
    }

    if(!validatePassword(password.value)){
      shouldAbort = true
    }

    if(!validateEmail(email.value)) {
      shouldAbort = true
    }

    if(selectedDate === null || !selectedDate.isValid() || !selectedDate.isBefore(maxDate) || !selectedDate.isAfter(minDate)) {
      shouldAbort = true
      setSelectedDateError('noDate')
    }

    if(!accepted) {
      shouldAbort = true
      setAcceptedError('notSet')
    }

    if (!checkRequirements() || shouldAbort) {
      return;
    }

    setIsRegistering(true);
    setError('');
    try {
      const initialSource = localStorage.getItem('initial_source');
      const currentSource = localStorage.getItem('current_source');
      const sst = localStorage.getItem('current_sst');

      const response = await fetch('/api/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: email.value,
          password: password.value,
          userName: userName.value.trim(),
          location,
          dateOfBirth: selectedDate?.format('YYYY-MM-DD'),   //formatter.format(selectedDate as Date, 'yyyy-MM-dd' as any),
          type: type === '0' ? 'PATRON' : 'CREATOR',
          initialSource: initialSource ? JSON.parse(initialSource) : null,
          currentSource: currentSource ? JSON.parse(currentSource) : null,
          sst: sst ? JSON.parse(sst) : null,
        }),
      });
      if (response.status === 201) {
        const res = await response.json();

        setRegisterSuccess(true);
        window.scrollTo({
          top: 0,
        });
        setError('');

        const windowRef = window as any
      
        windowRef._mtm = windowRef._mtm || [];
        windowRef._mtm.push({'event': 'funnel_reg_send', gender: type === '0'? 'm' : 'f'});
        
        windowRef.dataLayer = windowRef.dataLayer || [];
        windowRef.dataLayer.push({'event': 'funnel_reg_send', gender: type === '0'? 'm' : 'f'});
      } else {
        const err = await response.json();
        setError(err.type);
        if (
          err.type === 'validation' &&
          err.validation[0].path[0] === 'email'
        ) {
          setEmail((oldVal) => ({
            value: oldVal.value,
            valid: false,
            errorMsg: 'Bitte eine andere Email-Adresse eingeben',
          }));
        }
      }
      setIsRegistering(false);
    } catch (error) {
      setIsRegistering(false);
      console.error(
        'You have an error in your code or there are Network issues.',
        error
      );
    }
  }
  return {
    registerSuccess,
    isRegistering,
    type,
    typeValid,
    location,
    selectedDate,
    selectedDateError,
    userName,
    email,
    password,
    minDate,
    maxDate,
    startDate,
    error,
    locationValid,
    accepted,
    acceptedError,
    setLocation,
    setLocationValid,
    setType,
    setTypeValid,
    setSelectedDate,
    setSelectedDateError,
    setAccepted,
    setAcceptedError,
    setError,
    handleSubmit,
    validatePassword,
    validateEmail,
    validateUserName,
    checkRequirements
  };
};
