import Chip from "@mui/material/Chip";

interface Props {
  icon?: any
  variant?: any
  color?: any
}

export default function VIPBadge(props: Props) {
  const { icon, variant, color } = props;

  return (
    <Chip
      size={variant ? variant : "small"}
      variant={variant ? variant : "outlined"}
      color={color ? color : "primary"}
      icon={icon}
      label="VIP"
      style={{
        fontSize: "12px",
        fontWeight: "bold",
        marginLeft: "0.5em",
        marginRight: "0.5em",
        marginBottom: "0",
        marginTop: 0,
        verticalAlign: "top",
        borderRadius: '3px !important',
      }}
    />
  );
}
