'use client';
import { makeStyles } from '@mui/styles';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { FormHelperText, InputLabel } from '@mui/material';
import {
  grayColor,
  primaryBoxShadow,
  primaryColor,
  whiteColor,
} from '../styles/base';

const useStyles = makeStyles({
  selectMenu: {
    '& > div > ul': {
      border: '0',
      padding: '5px 0',
      margin: '0',
      boxShadow: 'none',
      minWidth: '100%',
      borderRadius: '4px',
      boxSizing: 'border-box',
      display: 'block',
      //fontSize: '15px',
      textAlign: 'left',
      listStyle: 'none',
      backgroundColor: whiteColor,
      backgroundClip: 'padding-box',
    }
  },
  largeSelect: {
    textAlign: 'center',
    padding: '12px 0 7px',
    fontSize: '18px',
    fontWeight: 800,
    color: '#333333 !important',
    lineHeight: '1.42857',
    textDecoration: 'none',
    //textTransform: 'uppercase',
    letterSpacing: '0',
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&[aria-owns] + input + svg': {
      transform: 'rotate(180deg)',
    },
    '& + input + svg': {
      transition: 'all 300ms linear',
    },
  },
});

type ProfileType = '0' | '1';

interface Props {
  setType(type: ProfileType): void;
  type: ProfileType | '';
  variant?: 'normal' | 'highlighted';
  isError?: boolean;
  useCleanWording?: boolean;
}

export function TypeSelect(props: Props) {
  const {
    variant = 'normal',
    setType,
    type,
    isError = false,
    useCleanWording = false,
  } = props;
  const classes = useStyles();

  return (
    <FormControl
      fullWidth
      key="type"
      margin="dense"
      variant="standard"
      error={isError}
      sx={{
        margin: '0 !important',
        marginBottom: '2px !important',
        '& > div': {
          '&:before': {
            borderBottomWidth: '1px !important',
            borderBottomColor: grayColor + ' !important',
          },
          '&:after': {
            borderBottomColor: primaryColor + '!important',
          },
        },
      }}
    >
      {!type && <InputLabel>Was bist du?</InputLabel>}
      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: variant === 'highlighted' ? classes.largeSelect : undefined,
        }}
        sx={{
          '.MuiSelect-icon': {
            marginRight: '8px',
          },
        }}
        value={type}
        label="Was bist du?"
        inputProps={{
          name: 'type',
          id: 'type',
        }}
        onChange={(event) => setType(event.target.value as ProfileType)}
      >
        <MenuItem
          key="PATRON"
          value="0"
          sx={{
            padding: '10px',
            paddingLeft: '10px',
            margin: '10px',
            borderRadius: '2px',
            transition: 'all 150ms linear',
            display: 'block',
            fontWeight: 500,
            lineHeight: '2',
            whiteSpace: 'nowrap',
            color: grayColor,
            paddingRight: '30px',
            '&.Mui-selected': {
              backgroundColor: primaryColor,
              color: whiteColor
            },
            '&:hover': {
              backgroundColor: primaryColor,
              color: whiteColor,
              ...primaryBoxShadow,
            },
          }}
        >
          Ich bin ein {useCleanWording ? 'Mann' : 'Sugardaddy'}
        </MenuItem>
        <MenuItem
          key="CREATOR"
          value="1"
          sx={{
            padding: '10px',
            paddingLeft: '10px',
            margin: '10px',
            borderRadius: '2px',
            transition: 'all 150ms linear',
            display: 'block',
            fontWeight: 500,
            lineHeight: '2',
            whiteSpace: 'nowrap',
            color: grayColor,
            paddingRight: '30px',
            '&.Mui-selected': {
              backgroundColor: primaryColor,
              color: whiteColor
            },
            '&:hover': {
              backgroundColor: primaryColor,
              color: whiteColor,
              ...primaryBoxShadow,
            },
          }}
        >
          Ich bin {useCleanWording ? 'eine Frau' : 'ein Sugarbabe'}
        </MenuItem>
      </Select>
      {isError && <FormHelperText>Bitte wähle aus, was du bist</FormHelperText>}
    </FormControl>
  );
}
