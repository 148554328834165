import classNames from 'classnames';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Grid, Box, Link } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { CTABlock } from './CTABlock';

// @mui/icons-material
import { Copyright, Favorite } from '@mui/icons-material';

import { styles, PrimaryContainer, BoundingContainer } from '../styles/common';
import { styles as footerStyles } from '../styles/footerStyle';

const useStyles = makeStyles({
  ...styles,
  ...footerStyles,
});

interface Props {
  withSignup?: boolean;
  showRegistrationHint?: boolean;
  baseUrl?: string;
  copyrightUrl?: string;
}

export const Footer = (props: Props) => {
  const theme = useTheme();
  const isAboveSm = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();
  const {
    withSignup = true,
    showRegistrationHint = false,
    baseUrl = '',
    copyrightUrl = 'getasugar.de',
  } = props;

  const items = [
    { label: 'AGB', href: baseUrl + '/agb' },
    { label: 'Datenschutz', href: baseUrl + '/datenschutz' },
    { label: 'Impressum', href: baseUrl + '/impressum' },
  ];

  return (
    <>
      {withSignup && (
        <PrimaryContainer>
          <CTABlock />
        </PrimaryContainer>
      )}
      {showRegistrationHint && (
        <PrimaryContainer>
          <BoundingContainer
            sx={{ textAlign: 'center', padding: '1em', color: '#AF9DC3' }}
          >
            Nur echte Profile
            <br />
            Auf getasugar findest du nur echte und viele mit Ausweis- oder Foto-
            Identifikation geprüfte Profile. Auf dieser Seite gezeigte Profile
            sind aus Datenschutzgründen beispielhaft zu sehen. Flirte nach
            deiner Registrierung mit echten Mitgliedern aus deiner Nähe.
          </BoundingContainer>
        </PrimaryContainer>
      )}
      <footer className={classes.footer}>
        <BoundingContainer sx={{ paddingBottom: '15px', paddingTop: '15px' }}>
          <Grid container alignItems="center">
            <Grid
              item
              xs={12}
              className={isAboveSm ? '' : classes.hasTextCentered}
            >
              <Grid
                container
                justifyContent="center"
                spacing={isAboveSm ? 3 : 1}
                direction="row"
              >
                {items.map((item, key) => (
                  <Grid item justifyContent="center" key={key}>
                    <Link
                      href={item.href}
                      className={classes.footerLink}
                      target="_blank"
                    >
                      {item.label}
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </BoundingContainer>

        <PrimaryContainer>
          <BoundingContainer
            sx={{
              padding: '1em',
            }}
          >
            <Box
              display="flex"
              justifyContent={'center'}
              alignItems="center"
              sx={{
                marginTop: '0.5rem', 
                marginBottom: '0.5rem'
              }}
            >
              <Copyright
                fontSize="inherit"
                color="inherit"
                style={{
                  marginBottom: '0.1em',
                  marginRight: '0.25em',
                }}
              />
              {new Date().getFullYear()} {copyrightUrl} - Made with
              <Favorite
                fontSize="inherit"
                color="inherit"
                style={{
                  marginBottom: '0.1em',
                  marginLeft: '0.25em',
                  marginRight: '0.25em',
                }}
              />
              in Hamburg
            </Box>
          </BoundingContainer>
        </PrimaryContainer>
      </footer>
    </>
  );
};
