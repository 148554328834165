'use client'
import { styled } from "@mui/material";
import { whiteColor } from "assets/styles/colors";
import { CSSProperties } from "react";

interface StyleProps {
  ownerState?: {
    styles?: CSSProperties
  };
}

export const ContentBox = styled('div', {
  name: 'ContentBox', // The component name
  slot: 'background', // The slot name
})<StyleProps>(({ theme, ownerState }) => ({
  backgroundColor: whiteColor,
  padding: '1rem',
  marginTop: 'calc(85px + 2rem)',
  marginBottom: '2rem',
  borderRadius: 5,
  paddingBottom: 30,
  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.15)",
  ...(ownerState?.styles || {})
}));