'use client'
import { 
	Button, 
	Checkbox, 
	FormControl, 
	FormControlLabel, InputAdornment, Link, 
	MobileStepper, 
	useTheme, 
	TextField, IconButton, Box, FormHelperText } from '@mui/material';
import { makeStyles } from "@mui/styles"
import { Email, KeyboardArrowLeft, Close, Visibility, VisibilityOff } from '@mui/icons-material'
import { DatePicker  } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { deDE } from '@mui/x-date-pickers/locales'
import 'dayjs/locale/de'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useEffect, useMemo, useState } from 'react'
import { LocationSuggest } from '../atoms/LocationSuggest'
import { RegisterUserName } from '../atoms/RegisterUserName';
import { useRegister } from '../hooks/useRegister';

import {styles} from '../styles/common'
import {styles as formStyle} from '../styles/formStyle'

import logo from 'assets/GS_Logo_Purple.svg'

const useStyles = makeStyles({
	...styles,
	...formStyle
})

interface Props {
	close():  void
}

export function StepRegisterForm(props: Props) {
	const { close } = props 
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
	const [showPassword, setShowPassword] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(0, prevActiveStep - 1));
  };

	const { 
    registerSuccess, 
    isRegistering, 
    location,
		locationValid,
    selectedDate,
		selectedDateError,
    userName,
    email,
    password,
		minDate,
    maxDate,
    startDate,
		accepted,
		acceptedError,
		error,
		setError,
    setLocation,
		setLocationValid,
    setType,
    setSelectedDate,
		setSelectedDateError,
    setAccepted,
		setAcceptedError,
    handleSubmit,
		validateEmail,
		validatePassword,
		validateUserName,
		checkRequirements
  } = useRegister()

	useEffect(() => {
		if(error) {
			if(['userNameTaken', 'userNameInvalid'].includes(error)) {
				setActiveStep(2)
			}
		}
	}, [error])

	const dateErrorMessage = useMemo(() => {
    switch (selectedDateError) {
      case 'maxDate': {
				return 'Du musst mindestens 18 Jahre sein um dich zu registrieren'
			}
      case 'minDate': {
        return 'Du kannst nicht älter als 120 Jahre sein';
      }

      case 'invalidDate': {
        return 'Das eingegebene Datum ist ungültig';
      }

			case 'noDate': {
				return 'Bitte ein gültiges Datum eingeben';
			}

      default: {
        return '';
      }
    }
  }, [selectedDateError]);

	let title: any = <div style={{textAlign: 'center'}}>Kostenlos anmelden &<br /> sofort Profile betrachten</div>, content, button
	switch(activeStep) {
		case 0:
			content = (
				<>
					<Box display={'flex'} >
						<Button 
							className={classes.isWhite}
							color="primary"
							variant='contained'
							onClick={() => {
								setType("0")
								handleNext()
							}}
							sx={{
								marginRight: '5px'
							}}
						>Als Mann {process.env['NX_CLEANED_WORDING'] === "0" ? '(Sugardaddy)' : ''} registrieren</Button>
						<Button 
							className={classes.isWhite}
							color="primary"
							variant='contained'
							onClick={() => {
								setType("1")
								handleNext()
							}}
							sx={{
								marginLeft: '5px'
							}}
						>Als Frau {process.env['NX_CLEANED_WORDING'] === "0" ? '(Sugarbabe)' : ''} registrieren</Button>
					</Box>
					<Box sx={{marginTop: '1.5em', textAlign: 'center'}}>Bleib - wenn du es möchtest - anonym: Kein echter Name, Adresse oder Telefonnummer nötig</Box>
				</>
			)
			break;
		case 1:
			content = (<>
				<Box sx={{textAlign: 'center', padding: '0.5em 0'}}>Gewünschten Ort eigeben:</Box>
				<LocationSuggest setLocation={(location) => {
					setLocation(location)
				}} location={location} setLocationValid={setLocationValid} locationValid={locationValid} />
			</>)

			button = (
				<Button 
					className={classes.isWhite}
					color="primary"
					variant='contained'
					sx={{
						marginTop: '1em'
					}}
					disabled={!locationValid || !location}
					onClick={() => {
						handleNext()
					}}
				>Weiter</Button>
			)
			break;
		case 2:
			content = (<>
				<Box sx={{textAlign: 'center', padding: '0.5em 0'}}>Gewünschter Nutzername:</Box>
				<div></div>
				<RegisterUserName 
					userName={userName} 
					error={error} 
					validateUserName={validateUserName} 
					setError={setError} 
					placeHolder="Wunsch-Name"
				/>
			</>)

			button = (
				<Button 
							className={classes.isWhite}
							color="primary"
							variant='contained'
							sx={{
								marginTop: '1em'
							}}
							disabled={!userName.valid || userName.value === ''}
							onClick={() => {
								handleNext()
							}}
						>Weiter</Button>
			)
			break;
		case 3:
			title = <div style={{textAlign: 'center'}}>Letzter Schritt:<br /> Du kannst direkt losflirten</div>

			content = (<>
			<FormControl
					fullWidth
					margin="dense"
					className={classes.formControl}
					error={!!dateErrorMessage}
				>
					<LocalizationProvider dateAdapter={AdapterDayjs} localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="de">
						<DatePicker
							openTo="day"
							format="DD.MM.YYYY"
							label="Geburtsdatum"
							maxDate={maxDate}
							minDate={minDate}
							views={["day", "month", "year"]}
							value={selectedDate}
							onChange={(date) => setSelectedDate(date)}
							onError={(newError) => setSelectedDateError(newError)}
							slotProps={{
								textField: {
									error: !!dateErrorMessage,
									helperText: dateErrorMessage,
									variant:"standard"
								},
								inputAdornment: {
									sx: {
										marginRight: 0,
										'.MuiButtonBase-root.MuiIconButton-edgeEnd': {
											marginRight: 0
										}
									}
								}
							}}
							
						/>
					</LocalizationProvider>
					</FormControl>
					{/*
						InputProps={{
								classes: {
									input: classes.input,
									root: classes.formControl,
								},
								endAdornment: (
									<InputAdornment position="end">
										<DateRange />
									</InputAdornment>
								),
							}}
					*/}
				<FormControl
					fullWidth
					margin="dense"
					error={!email.valid || error === "emailTaken" || error === "emailTrash"}
					className={classes.formControl}
				>
					<TextField
						variant="standard"
						error={!email.valid || error === "emailTaken" || error === "emailTrash"}
						helperText={
							email.errorMsg
								? email.errorMsg
								: error === "emailTaken"
								? "E-Mail ist bereits registriert"
								: error === "emailTrash"
								? "Dieser E-Mail-Anbieter ist nicht erlaubt"
								: undefined
						}
						id="email"
						label="E-Mail"
						autoComplete="username"
						fullWidth
						value={email.value}
						onChange={(e) => {
							if(['emailTaken'].includes(error)) {
								setError('')
							}
							validateEmail(e.target.value)
						}}
						InputProps={{
							classes: {
								input: classes.input,
							},
							endAdornment: (
								<InputAdornment position="end">
									<IconButton>
										<Email />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</FormControl>
				<FormControl
					fullWidth
					margin="dense"
					className={classes.formControl}
					error={!password.valid}
				>
					<TextField
						variant="standard"
						error={!password.valid}
						helperText={password.errorMsg}
						id="password"
						label="Passwort"
						autoComplete="new-password"
						type={showPassword ? "text" : "password"}
						fullWidth
						value={password.value}
						onChange={(e) => validatePassword(e.target.value)}
						InputProps={{
							classes: {
								input: classes.input,
							},
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={() => setShowPassword(!showPassword)}>
										{showPassword ? (
											<Visibility />
										) : (
											<VisibilityOff />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</FormControl>
				<FormControl fullWidth margin="dense" error={!!acceptedError}>
					<FormControlLabel
						control={<Checkbox name="AGB" color="primary" />}
						onChange={(event) => {
							setAccepted((event.target as any).checked)
							setAcceptedError('')
						}}
						value={accepted}
						label={
							<span className={classes.isBlack}>
								Hiermit bestätige ich die{" "}
								<Link
									href="https://www.getasugar.de/datenschutz"
									target="_blank"
									className={classes.isPrimary}
								>
									Datenschutzerklärung
								</Link>{" "}
								und die
								{" "}
								<Link
									href="https://www.getasugar.de/agb"
									target="_blank"
									className={classes.isPrimary}
								>
									AGB
								</Link>{" "}
								gelesen zu haben und akzeptiere diese.
							</span>
						}
					/>
					{acceptedError && <FormHelperText>Bitte akzeptiere die AGB und Datenschutzerklärung</FormHelperText>}
				</FormControl>
			</>)

			button = <Button
					color="primary"
					variant="contained"
					sx={{
						marginTop: '1em'
					}}
					className={classes.isWhite}
					disabled={!checkRequirements() && isRegistering}
					onClick={() => handleSubmit()}
				>
					{isRegistering
						? "wird gespeichert..."
						: "jetzt kostenlos registrieren"}
				</Button>
			break;
	}

	if(registerSuccess) {
		return (
			<div>
				<Box sx={{marginBottom: '1em', position: 'relative', display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
					<IconButton
						sx={{
							position: 'absolute',
							left: 0,
							top: 0,
							padding: '8px 8px 8px 0'
						}}
						disableRipple={true}
						onClick={() => {
							close()
						}}
					>
						<Close />
					</IconButton>
					<img
						src={(logo as any).src}
						alt="getasugar.de"
						style={{ maxWidth: '160px' }}
					/>
				</Box>
				<h4
					className={classes.isBold + " " + classes.hasTextCentered}
				>
					Anmeldung erfolgreich
				</h4>
				<p>
					Das hat geklappt! Wir haben dir eine E-Mail zur
					Aktivierung geschickt. Klicke dort auf den Link und
					flirte los!
				</p>
				<p>
					Falls keine E-Mail in deinem Postfach ist, schau auch in
					deinen Spam-Ordner.
				</p>
				<Box sx={{textAlign: 'center'}}>
					<Button 
						className={classes.isWhite}
						color="primary"
						variant='contained'
						sx={{
							marginTop: '1em'
						}}
						onClick={() => {
							window.location.href = 'https://web.getasugar.de/login'
						}}
					>Zum Login</Button>
				</Box>
			</div>
		)
	}

  return (
		<div>
			<Box sx={{marginBottom: '1em', position: 'relative', display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
					<IconButton
						sx={{
							position: 'absolute',
							left: 0,
							top: 0,
							padding: '8px 8px 8px 0'
						}}
						disableRipple={true}
					onClick={() => {
						if(activeStep === 0) {
							close()
						} else {
							handleBack()
						}
					}}>
						{activeStep === 0 ? <Close /> : <KeyboardArrowLeft />}
					</IconButton>
					<img
						src={(logo as any).src}
						alt="getasugar.de"
						style={{ maxWidth: '160px' }}
					/>
			</Box>
			<div>{title}</div>
			<MobileStepper
				variant="dots"
				steps={4}
				position="static"
				activeStep={activeStep}
				backButton={null}
				nextButton={null}
				sx={{
					paddingTop: '1em',
					paddingBottom: '1em',
					'.MuiMobileStepper-dots': {
						margin: '0 auto'
					}
				}}
			/>
			<div>
				{content}
			</div>
			<Box sx={{textAlign: 'center'}}>
				{button}
			</Box>
		</div>
  );
}
