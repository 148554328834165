'use client'
import { ChangeEvent, useEffect, useState } from 'react';
import { FormControl, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import Autocomplete from '@mui/material/Autocomplete';
import { Subject, defer, switchMap } from "rxjs";
import { debounceTime } from "rxjs/operators";

import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Location } from '@sugar/models'

import {styles as formStyle} from '../styles/formStyle'

const useStyles = makeStyles({
	...formStyle,
  popupIndicatorOpen: {
    transform: "none !important",
  },
  marginRight: {
    right: "12px",
  },
  formControl: {
    marginTop: "1px",
  },
})

interface Props {
  setLocation(location: Location | null): void;
  setLocationValid(valid: boolean): void
  locationValid: boolean
  location: Location | null
}

export const LocationSuggest = (props: Props) => {
  const classes = useStyles();
  const [subject] = useState<Subject<string>>(new Subject<string>())
  const [options, setOptions] = useState<Location[]>([])
  const { setLocation, setLocationValid, locationValid, location } = props

  useEffect(() => {
    const sub = subject
      .pipe(
        debounceTime(200),
        switchMap((query: string) =>
          defer(async () => {
            if (query.length < 2) {
              return [];
            }
            const url = '/api/location?query=' + encodeURIComponent(query);
            try {
              const response = await fetch(url);
              if (response.status === 200) {
                const res = await response.json();

                return res.data;
              } else {
                return []
              }
            } catch (error) {
              console.error(
                'You have an error in your code or there are Network issues.',
                error
              );
              return []
            }
          })
        )
      )
      .subscribe((suggestions: Location[]) => {
        setOptions(suggestions);
      });
    return () => {
      sub.unsubscribe();
    };
  }, [subject]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    subject.next(event.target.value);
  };

  return (<FormControl
    fullWidth
    margin="dense"
    className={classes.formControl}
    error={!locationValid}
  >
    <Autocomplete
      id="location-suggest"
      popupIcon={<LocationOnIcon style={{ transform: 'none !important' }} />}
      forcePopupIcon
      noOptionsText="keine Ergebnisse"
      classes={{
        input: classes.input,
        groupLabel: classes.labelRoot,
        popupIndicatorOpen: classes.popupIndicatorOpen,
        endAdornment: classes.marginRight,
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.suburb) {
          return `${option.city} - ${option.suburb}`;
        }
        return option.city;
      }}
      options={options}
      filterOptions={(x) => x}
      freeSolo
      clearOnBlur
      value={location || null}
      autoHighlight={true}
      onChange={(ev, selection, reason) => {
        if (
          reason === 'selectOption' &&
          selection &&
          typeof selection === 'object'
        ) {
          const val = {
            ...selection,
            coordinates: {
              latitude: selection.coordinates.latitude,
              longitude: selection.coordinates.longitude,
            },
          };
          delete (val as any).__typename;
          setLocation(val);
          setLocationValid(true);
        } else if (reason === 'clear' || reason === 'removeOption') {
          setLocation(null);
          setLocationValid(false);
          setOptions([]);
        }
      }}
      renderInput={(params) => (
        <TextField
          variant="standard"
          color="primary"
          {...params}
          label="Ort eingeben"
          fullWidth
          autoComplete="off"
          onChange={handleChange}
        />
      )}
      renderOption={(props: any, option) => {
        return (
          <Grid container alignItems="center" {...props}>
            <Grid item key="locationIcon">
              <LocationOnIcon />
            </Grid>
            <Grid item xs key="locationName">
              {option.suburb
                ? `${option.city} - ${option.suburb}`
                : option.city}
              <Typography variant="body2" color="textSecondary">
                {option.country}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
    {!locationValid && <FormHelperText>Bitte gib einen Ort an</FormHelperText>}
  </FormControl>);
};
