'use client'
import { useState, useEffect } from "react";
import { useRouter } from "next/navigation";

// @mui/core components
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";

// @mui/icons
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

// core components
import Button from "../atoms/Button";
import { successColor } from "../styles/base";

export function HeaderLinks(props) {
  const [belowFold, setBelowFold] = useState(false);

  const handleScroll = () => {
    const height = window.innerHeight;
    const position = window.pageYOffset;

    position >= height / 3 ? setBelowFold(true) : setBelowFold(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: false });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const theme = useTheme();
  const aboveSm = useMediaQuery(theme.breakpoints.up("sm"));
  const router = useRouter();

  const { adaptiveFold = false } = props;

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ gap: "1em", fontSize: "0.9em" }}
    >
      <Button
        onClick={() => {
          router.push("/register");
        }}
        style={{
          backgroundColor: successColor,
          transition: "opacity 0.5s linear",
          opacity: belowFold || !adaptiveFold ? 1 : 0,
          overflow: "hidden",
        }}
      >
        {aboveSm ? "Kostenlos registrieren" : "Registrieren"}
      </Button>

      {aboveSm && !belowFold && adaptiveFold && (
        <Button
          href={process.env.NEXT_PUBLIC_FRONTEND_BASEURL + "/login"}
          color="transparent"
        >
          Login
        </Button>
      )}

      {(!aboveSm || belowFold || !adaptiveFold) && (
        <Button
          href={process.env.NEXT_PUBLIC_FRONTEND_BASEURL + "/login"}
          color="transparent"
        >
          <ExitToAppIcon style={{ marginRight: "0" }} />
        </Button>
      )}
    </Box>
  );
}
