// @material-ui/core components
import { Grid, Typography } from '@mui/material';

const params = {
  title: 'Allgemeine Geschäftsbedingungen',
  teaser: 'Hier finden Sie die AGB von getasgugar.de',
};

export function LegalTerms() {

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h1" component="h1">
          Allgemeine Geschäftsbedingungen
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <h2>1. Service und Betreiber, Geltungsbereich und Allgemeines</h2>

        <p>
          Der Service getasugar (nachfolgend
          auch „der Service“ genannt) ist ein Service der lovely vibes GmbH,
          Ballindamm 27, 20095 Hamburg (nachfolgend auch „der Betreiber“
          genannt). Der Service bietet seinen Kunden während der
          Vertragslaufzeit den Zugriff auf eine Online-Plattform, über die ein
          gemeinsames Kennenlernen ermöglicht wird.
        </p>

        <p>
          Nutzer des Services können nur volljährige Personen (ab 18 Jahren)
          sein. Personen unter 18 Jahren steht der Service nicht offen. Mit der
          Anmeldung beim Service (siehe Ziff. 4.) versichert der Nutzer,
          mindestens 18 Jahre alt zu sein.
        </p>

        <p>
          Mit der Anmeldung bei dem Service (vgl. hierzu auch Ziffer 4.)
          akzeptiert der Nutzer die vorliegenden Allgemeinen
          Geschäftsbedingungen für die Nutzung des Services (nachfolgend „die
          Geschäftsbedingungen“ genannt). Der Betreiber erbringt den Service
          ausschließlich auf der Grundlage der Geschäftsbedingungen in ihrer zum
          Zeitpunkt der Anmeldung des Nutzers gültigen Fassung.
        </p>

        <p>
          Der Service, seine Inhalte und die im Rahmen des Services einsehbaren
          Daten stehen dem Nutzer nur zu rein privaten bzw. nicht kommerziellen
          Zwecken zur Verfügung. Jede Nutzung zu anderen Zwecken, insbesondere
          zu gewerblichen oder kommerziellen Zwecken, ist untersagt.
        </p>
      </Grid>

      <Grid item xs={12}>
        <h2>2. Leistungen des Services</h2>
        <p>
          Der Service ist ein Internetportal für kontaktsuchende Kunden, das
          seinen Kunden die Möglichkeit bietet, sich mit eigenen Inhalten zu
          präsentieren und mit anderen Kunden zu interagieren. Der Betreiber
          bietet dem Nutzer Zugriff auf seine Plattform. Diese Plattform
          ermöglicht dem Nutzer den Aufbau von Partner- und Freundschaften mit
          anderen Mitgliedern. Außerdem enthält diese Plattform Profile und
          Informationen über andere Nutzer. Die Nutzer können sich auf der
          Plattform die Profile anderer Nutzer ansehen und von diesen in
          eingeschränktem Maße kontaktiert werden sowie ein eigenes Profil mit
          Fotos erstellen. Der Kunde tritt gegenüber dritten Kunden wahlweise
          unter seinem Vornamen oder einem anonymen Wunschnamen auf. Die Nutzer
          haben auch die Möglichkeit, ihre bereits bestehende kostenlose
          Mitgliedschaft zu einer kostenpflichtigen "VIP Mitgliedschaft"
          aufzuwerten. Einem "VIP Mitglied" stehen zusätzlich zu den Funktionen
          der kostenlosen Basis Mitgliedschaft weitere Funktionen zur Verfügung.
        </p>
        <p>
          Der Betreiber verspricht keine erfolgreiche Kontaktaufnahme zu anderen
          Nutzern des Services. Gegenstand des Vertrages ist auch nicht eine
          Heiratsvermittlung oder Eheanbahnung. Leistungsgegenstand ist
          lediglich die - in Abhängigkeit von der Art der erworbenen
          Mitgliedschaft unterschiedlich ausgestaltete - Verschaffung der
          Möglichkeit zur Kontaktaufnahme unter den Nutzern des Services. Der
          Service erarbeitet insbesondere keine Vorschläge für Kontakte, weder
          persönlich, noch durch automatisierte Verfahren.
        </p>
      </Grid>
      <Grid item xs={12}>
        <h2>3. Gegenstand des Servicevertrages und Vertragsabschlüsse</h2>
        <p>
          Gegenstand der Serviceverträge ist jeweils die Nutzung der Services
          durch den Nutzer im Umfang der gewählten Mitgliedschaft. Die Nutzung
          des Services ist nur nach vorheriger Anmeldung durch den Nutzer und
          Annahme der Anmeldung durch den Betreiber möglich. Den Nutzern stehen
          dabei zwei Varianten der Nutzung des Services bzw. der Mitgliedschaft
          zur Auswahl:
        </p>
        <p>
          Zum einen gibt es die Möglichkeit der unentgeltlichen, eingeschränkten
          Nutzung des Services im Rahmen der Basismitgliedschaft (nachfolgend
          „Basismitgliedschaft“ genannt);
        </p>

        <p>
          Zum anderen gibt es die Möglichkeit der erweiterten, entgeltlichen
          Nutzung des Services im Rahmen der VIP Mitgliedschaften (nachfolgend
          „VIP Mitgliedschaften“ genannt). Diese setzt eine zuvor erfolgte
          Registrierung als Basismitglied voraus.
        </p>

        <p>
          Die Beschreibung der Mitgliedschaften, deren Leistungsumfänge sowie
          die Preise der VIP Mitgliedschaften sind den Darstellungen im Rahmen
          des Services zu entnehmen.
        </p>

        <p>
          Die Möglichkeit zur Nutzung des angebotenen Services bzw. die
          Möglichkeit der Anmeldung bzw. Registrierung zu dem Service – gleich
          ob kostenlos (Basismitgliedschaft) oder kostenpflichtig (VIP
          Mitgliedschaft) – ist jeweils unverbindlich und stellt insbesondere
          kein verbindliches Angebot des Betreibers auf Abschluss eines
          entsprechenden Nutzungsvertrages dar. Vielmehr ist darin eine
          Aufforderung an den Nutzer zur Abgabe eines Angebots auf Abschluss
          eines Nutzungsvertrages zu sehen. Mit Abschluss des jeweiligen
          Anmelde- bzw. Bestellvorgangs durch den Nutzer auf dem Internet-Portal
          des Services gibt dieser ein verbindliches Angebot auf Abschluss des
          Vertrages (Basismitgliedschaft oder VIP Mitgliedschaft) über die
          Nutzung des Services ab. Der Betreiber kann dieses Angebot des Nutzers
          durch gesonderte E-Mail oder durch Bereitstellung des entsprechenden
          Services innerhalb von drei Werktagen annehmen, andernfalls ist der
          Nutzer nicht mehr an sein Angebot gebunden. Erst durch diese Annahme
          kommt der Vertrag zwischen dem Nutzer und dem Betreiber zustande. Der
          Betreiber ist nicht zum Vertragsschluss verpflichtet. Der Betreiber
          kann die Annahme der Anmeldung bzw. Bestellung (Abschluss des
          Vertrages) ohne Angabe von Gründen ablehnen.
        </p>
      </Grid>
      <Grid item xs={12}>
        <h2>4. Nutzerprofile</h2>
        <p>
          Nutzer können ihr Benutzerprofil im Rahmen der vorliegenden
          Geschäftsbedingungen nach ihren Vorstellungen gestalten. Dabei sind
          die Beschränkungen der Ziff. 6 zu beachten.
        </p>

        <p>
          Mit dem Einstellen von Inhalten im Rahmen des Services räumt der
          Nutzer dem Betreiber jeweils ein unentgeltliches und übertragbares
          Nutzungsrecht an den jeweiligen Inhalten ein, insbesondere
        </p>

        <ul>
          <li>
            zur Speicherung der Inhalte auf dem Server des Betreibers sowie
            deren Veröffentlichung, insbesondere deren öffentlicher
            Zugänglichmachung (z.B. durch Anzeige der Inhalte im Internet im
            Rahmen des Services),
          </li>
          <li>
            zur Bearbeitung und Vervielfältigung, soweit dies für die Vorhaltung
            bzw. Veröffentlichung der jeweiligen Inhalte erforderlich ist,
          </li>
          <li>
            und zur Einräumung von - auch entgeltlichen - Nutzungsrechten
            gegenüber Dritten an Inhalten im Rahmen der Nutzung des Services.
          </li>
        </ul>

        <p>
          Das vorstehende Nutzungsrechte erlischt wenn und sobald der Nutzer die
          von ihm eingestellten Inhalte wieder vom Service entfernt. Der
          Betreiber bleibt jedoch berechtigt, zu Sicherungs- und/oder
          Nachweiszwecken erstellte Kopien der Inhalte aufzubewahren. Die
          dritten Nutzern an vom Nutzer eingestellten Inhalten zuvor
          eingeräumten Nutzungsrechte bleiben ebenfalls unberührt.
        </p>

        <p>
          Der Nutzer ist für die von ihm eingestellten Inhalte allein und voll
          verantwortlich. Der Betreiber übernimmt keine Überprüfung der Inhalte
          auf Vollständigkeit, Richtigkeit, Rechtmäßigkeit, Aktualität, Qualität
          und Eignung für einen bestimmten Zweck. Der Nutzer versichert
          gegenüber dem Betreiber und steht dafür ein, dass er alleiniger
          Verfügungsberechtigter hinsichtlich der von ihm eingestellten Inhalte
          ist, insbesondere, dass er berechtigt ist, dem Betreiber die hier
          eingeräumten Rechte einzuräumen und dass er keine der
          vertragsgegenständlichen Rechteeinräumung entgegenstehende Verfügung
          getroffen hat oder treffen wird. Der Nutzer stellt den Betreiber von
          allen Ansprüchen Dritter im Zusammenhang mit der vertragsgemäßen
          Nutzung der Inhalte frei, einschließlich der Kosten angemessener
          Rechtsverteidigung, hilfsweise entschädigt er ihn. Dies gilt nicht,
          soweit der Nutzer die Rechtsverletzung nicht zu vertreten hat.
        </p>

        <p>
          Der Betreiber behält sich das Recht vor, das Einstellen von Inhalten
          durch den Nutzer abzulehnen und/oder bereits eingestellte Inhalte
          (einschließlich privater Nachrichten, Gästebucheinträge und sonstiger
          Mitteilungen) ohne vorherige Ankündigung zu bearbeiten, zu sperren
          oder zu entfernen, sofern das Einstellen der Inhalte durch den Nutzer
          oder die eingestellten Inhalte selbst zu einem Verstoß gegen Ziff. 6
          der Geschäftsbedingungen geführt haben oder konkrete Anhaltspunkte
          dafür vorliegen, dass es zu einem schwerwiegenden Verstoß gegen Ziff.
          6 der Geschäftsbedingungen kommen wird. Der Betreiber wird hierbei
          jedoch auf die berechtigten Interessen des Nutzers Rücksicht nehmen
          und das mildeste Mittel zur Abwehr des Verstoßes wählen.
        </p>
      </Grid>
      <Grid item xs={12}>
        <h2>5. Pflichten des Nutzers</h2>
        <p>
          Im Rahmen der Anmeldung zur Basismitgliedschaft, der Bestellung einer
          VIP Mitgliedschaft sowie der Anlage des Nutzerprofils werden
          verschiedene Angaben des Nutzers abgefragt. Dabei kann es sich sowohl
          um Pflichtangaben als auch um optionale Angaben handeln. Der Nutzer
          versichert, dass die von ihm gemachten Angaben in jedem Fall
          vollständig und richtig sind, und dass insbesondere die von ihm
          hinterlegten Kontaktdaten gültig und ihm zugeordnet sind. Der Nutzer
          ist verpflichtet, Änderungen bezüglich der von ihm gemachten
          Pflichtangaben unverzüglich selber im Profil anzupassen.
        </p>

        <p>
          Der Nutzer ist verpflichtet, seine Zugangsdaten für die Nutzung des
          Services geheim zu halten. Insbesondere ist es Nutzern untersagt, die
          Zugangsdaten Dritten mitzuteilen oder Dritten die Nutzung der Services
          mit Zugangsdaten des Nutzers zu ermöglichen. Sofern für den Nutzer
          begründeter Anlass zur Vermutung besteht, dass ein Dritter Kenntnis
          von seinen Zugangsdaten erlangt haben könnte, hat er den Betreiber
          unverzüglich hierüber in Kenntnis zu setzen und sein Passwort zu
          ändern.
        </p>

        <p>
          Im Rahmen der Nutzung des Services hat der Nutzer folgendes zu
          unterlassen:
        </p>

        <p>
          Die im Rahmen des Services für andere Nutzer des Services sichtbare
          Wiedergabe, Einblendung oder sonstige Angabe von Kontaktdaten (z.B.
          Telefonnummer, Anschriften, E-Mail-Adressen, etc.) oder sonstigen
          Informationen (z.B. Links zu Profilen des Nutzers auf anderen sozialen
          Netzwerken oder sonstigen Services, Nutzernamen auf anderen sozialen
          Netzwerken oder sonstigen Services, etc.), die zur Kontaktaufnahme
          zwischen Nutzern des Services unter Umgehung des Services bzw. seiner
          Benachrichtigungssysteme dienen können.
        </p>

        <p>
          Jegliche Nutzung des Services, seiner Inhalte oder der im Rahmen des
          Services einsehbaren Daten zu gewerblichen oder kommerziellen Zwecken,
          insbesondere jegliche Kontaktaufnahme mit oder die sonstige Ansprache
          von anderen Nutzern des Services im Rahmen des Services zu
          vorgenannten Zwecken.
        </p>

        <p>
          Inhalte des Services oder Daten der Nutzer des Services systematisch
          auszulesen oder zu kopieren oder sonst zu exportieren, um sie
          außerhalb des Services zu nutzen.
        </p>

        <p>
          Aktivitäten sowie die Veröffentlichung von Inhalten, gleich welcher
          Art, die gegen geltendes Recht verstoßen, Rechte Dritter verletzen
          oder gegen die Grundsätze des Jugendschutzes verstoßen. Insbesondere
          sind Ihnen folgende Handlungen untersagt:
        </p>

        <p>
          Die Verbreitung, das Angebot und die Bewerbung pornografischer, gegen
          Jugendschutzgesetze, gegen Datenschutzrecht und/oder gegen sonstiges
          Recht verstoßender und/oder betrügerischer Inhalte, Services und/oder
          Produkte; die Verwendung von Inhalten, durch die andere Nutzer oder
          Dritte beleidigt oder verleumdet werden; die Nutzung, das
          Bereitstellen und das Verbreiten von Inhalten, Services und/oder
          Produkten, die gesetzlich geschützt oder mit Rechten Dritter (z.B.
          Urheberrechte) belastet sind, ohne hierzu ausdrücklich berechtigt zu
          sein.
        </p>

        <p>
          Unabhängig von einem eventuellen Gesetzesverstoß bei der Einstellung
          eigener Inhalte im Rahmen des Services sowie bei der Kommunikation mit
          anderen Nutzern (z.B. durch Versendung persönlicher Mitteilungen,
          durch die Teilnahme an Diskussionsforen oder das Verfassen von
          Gästebucheinträgen) die folgenden Aktivitäten: Die Verbreitung von
          Viren, Trojanern und anderen schädlichen Dateien; die Versendung von
          Junk- oder Spam-Mails sowie von Kettenbriefen; die Verbreitung
          anstößiger, sexuell geprägter, obszöner oder diffamierender Inhalte
          bzw. Kommunikation sowie solcher Inhalte bzw. Kommunikation die
          geeignet sind/ist, Rassismus, Fanatismus, Hass, körperliche Gewalt
          oder rechtswidrige Handlungen zu fördern bzw. zu unterstützen (jeweils
          explizit oder implizit); die Belästigung anderer Teilnehmer, z.B.
          durch mehrfaches persönliches Kontaktieren ohne oder entgegen der
          Reaktion des anderen Nutzers sowie das Fördern bzw. Unterstützen
          derartiger Belästigungen; die Aufforderung an andere Nutzer zur
          Preisgabe von Kennwörtern oder personenbezogener Daten für
          kommerzielle oder rechts- bzw. gesetzeswidrige Zwecke; die Verbreitung
          und/oder öffentliche Wiedergabe bzw. Zugänglichmachung von im Rahmen
          des Services verfügbaren Inhalten, soweit dies nicht ausdrücklich vom
          jeweiligen Urheber gestattet oder als Funktionalität im Rahmen des
          Services ausdrücklich zur Verfügung gestellt wird.
        </p>

        <p>
          Jede Handlung, die geeignet ist, den reibungslosen Betrieb des
          Services zu beeinträchtigen, insbesondere die Systeme des Betreibers
          übermäßig zu belasten.
        </p>
      </Grid>
      <Grid item xs={12}>
        <h2>6. Verfügbarkeit des Services</h2>
        <p>
          Der Service ist durchgehend 24 Stunden, sieben Tage die Woche
          verfügbar mit einer Verfügbarkeit von 98 % im Monatsmittel. Hiervon
          ausgenommen sind Ausfallzeiten durch Wartung und Software-Updates
          sowie Zeiten, in denen der Webserver aufgrund von technischen oder
          sonstigen Problemen, die nicht im Einflussbereich des Betreibers
          liegen (höhere Gewalt, Verschulden Dritter etc.), über das Internet
          nicht zu erreichen ist. Sofern für den Betreiber absehbar ist, dass
          Ausfallzeiten für Wartung und Software-Updates länger als drei Stunden
          dauern, wird der Anbieter dies dem Kunden mindestens drei Kalendertage
          vor Beginn der jeweiligen Arbeiten mitteilen.
        </p>
      </Grid>
      <Grid item xs={12}>
        <h2>7. Dauer und Beendigung sowie Verlängerung der Nutzungsverträge</h2>
        <p>
          Die Basismitgliedschaft läuft auf unbestimmte Zeit und kann vom Nutzer
          jederzeit gekündigt werden. Der Betreiber kann die Basismitgliedschaft
          unter Einhaltung einer Kündigungsfrist von 14 Kalendertagen zum
          Monatsende per Email kündigen.
        </p>

        <p>
          Die VIP Mitgliedschaften werden mit Laufzeiten von 1, 3, 6 und 12
          Monaten angeboten. Einzelheiten sind der jeweiligen
          Produktbeschreibung zu entnehmen. Bei der VIP Mitgliedschaft mit
          1-monatiger Laufzeit verlängert sich die Mitgliedschaft jeweils um
          einen weiteren Monat, wenn der Nutzer die Mitgliedschaft nicht
          spätestens bis eine Woche vor Ablauf der Mitgliedschaft
          (einschließlich optionaler Verlängerungszeiträume) in Textform per
          Email oder eingeloggt auf der Plattform in den Einstellungen unter VIP
          Paket verwalten per Klick auf den Kündigungsbutton kündigt, bzw. der
          Betreiber nicht spätestens 3 Kalendertage vor Ablauf der
          Mitgliedschaft (einschließlich optionaler Verlängerungszeiträume)
          schriftlich, per Email kündigt. Bei VIP Mitgliedschaften von mehr als
          einem Monat anfänglicher Laufzeit (3, 6 und 12 Monate) verlängert sich
          die Mitgliedschaft jeweils automatisch auf unbestimmte Zeit, sofern
          der Kunde seinen Vertrag nicht zum Ablauf der Erstlaufzeit unter
          Einhaltung der Kündigungsfrist von zwei Wochen (3 und 6 Monate
          Laufzeit) bzw. einem Monat (12 Monate Laufzeit) ordentlich kündigt.
          Nach erfolgter Vertragsverlängerung, erfolgt die Zahlung zum
          vereinbarten Preis monatlich im Voraus, wobei die VIP Mitgliedschaft
          jederzeit mit einer Frist von einem Monat ordentlich gekündigt werden
          kann indem der Nutzer schriftlich per Email oder eingeloggt auf der
          Plattform in den Einstellungen unter VIP Paket verwalten per Klick auf
          den Kündigungsbutton kündigt bzw. wenn der Betreiber nicht spätestens
          2 Wochen vor Ablauf der Mitgliedschaft (einschließlich optionaler
          Verlängerungszeiträume) per Email kündigt. Maßgeblich für den Beginn
          der Mitgliedschaft ist Annahme der Anmeldung des Nutzers durch den
          Betreiber (Vertragsschluss).
        </p>

        <p>
          Das Recht der Parteien, den Vertrag aus wichtigem Grund zu kündigen,
          wird durch vorstehende Kündigungsregelungen nicht berührt.
        </p>

        <p>
          Mit Wirksamwerden der Kündigung endet das Vertragsverhältnis und der
          Nutzer darf den vertragsgemäßen Zugang nicht mehr nutzen. Der
          Betreiber ist berechtigt, den Benutzernamen sowie das Passwort mit
          Wirksamwerden der Kündigung zu sperren.
        </p>

        <p>
          Der Betreiber ist berechtigt, mit Ablauf von 30 Kalendertagen nach
          Wirksamwerden der Kündigung und nach Ablauf etwaiger gesetzlicher
          Vorhaltungsfristen sämtliche im Rahmen der Nutzung des Services durch
          den Nutzer entstandene Daten unwiederbringlich zu löschen.
        </p>
      </Grid>
      <Grid item xs={12}>
        <h2>8. Sperrung des Zugangs</h2>
        <p>
          Der Betreiber kann den Zugang des Nutzers zum Service vorübergehend
          oder dauerhaft sperren, wenn der Nutzer gegen diese
          Geschäftsbedingungen und/oder geltendes Recht verstößt bzw. verstoßen
          hat, oder wenn der Betreiber ein sonstiges berechtigtes Interesse an
          der Sperrung hat. Bei der Entscheidung über eine Sperrung wird der
          Betreiber die berechtigten Interessen des Nutzers angemessen
          berücksichtigen.
        </p>

        <p>
          Im Falle der vorübergehenden bzw. dauerhaften Sperrung sperrt der
          Betreiber die Zugangsberechtigung und benachrichtigt den Nutzer
          hierüber per E-Mail.
        </p>

        <p>
          Im Falle einer vorübergehenden Sperrung reaktiviert der Betreiber nach
          Ablauf der Sperrzeit die Zugangsberechtigung und benachrichtigt den
          Nutzer hierüber per E-Mail. Eine dauerhaft gesperrte
          Zugangsberechtigung kann nicht wiederhergestellt werden. Dauerhaft
          gesperrte Nutzer sind von der Teilnahme am Service dauerhaft
          ausgeschlossen und dürfen sich nicht erneut beim Service anmelden.
        </p>
      </Grid>
      <Grid item xs={12}>
        <h2>9. Zahlung</h2>
        <p>Zur Zahlung stehen folgende Zahlungsarten zur Verfügung:</p>

        <ul>
          <li>
            Kreditkarte
            <br />
            Akzeptiert werden alle gängigen Kreditkarten, wie unter anderem
            Visa, Mastercard und American Express. Die Kreditkarte wird mit
            Abschluss des Vertrages (Annahme der VIP Mitgliedschaft / Erwerb von
            Coin-Paketen) belastet.
          </li>
          <li>
            Sofortüberweisung <br />
            Bei der Sofortüberweisung erfolgt die Überweisungsgutschrift
            unmittelbar. Der Nutzer benötigt hierfür Kontonummer, Bankleitzahl,
            PIN und TAN. Über das verschlüsselte, für Händler nicht zugängliche
            Zahlformular stellt Sofortüberweisung automatisiert und in Echtzeit
            eine Überweisung im Online-Bankkonto des Nutzers ein. Eine
            Registrierung ist nicht erforderlich. Der Kaufbetrag wird dabei
            sofort und direkt an das Konto des Betreibers überwiesen.
          </li>
          <li>
            Lastschriftverfahren <br />
            Beim Lastschriftverfahren wird der Rechnungsbetrag einfach und
            bequem unter Verwendung der vom Nutzer angegebenen Kontodaten
            innerhalb von durchschnittlich zwei bis drei Werktagen vom Konto des
            Nutzers abgebucht.
          </li>
          <li>
            giropay <br />
            Für eine Bezahlung mit giropay wählt der Nutzer seine Bank oder
            Sparkasse aus, bei der er bezahlen möchten und loggt sich
            anschließend mit den Online-Banking-Zugangsdaten (z.B. Kontonummer
            und PIN) ein. Mit einer TAN wird die vorausgefüllte
            giropay-Überweisung freigegeben.
          </li>
        </ul>

        <p>
          Der Betreiber behält sich vor, jederzeit weitere Bezahlmöglichkeiten
          anzubieten bzw. einzelne Bezahlmöglichkeiten von der Plattform zu
          nehmen oder diese selektiv nach Region, Produkt oder Nutzer
          auszuspielen.
        </p>
        <p>
          Nutzer sind nicht berechtigt, gegenüber Forderungen des Betreibers
          aufzurechnen, es sei denn, die Gegenansprüche des Nutzers sind
          rechtskräftig festgestellt oder unbestritten. Der Nutzer ist zur
          Aufrechnung gegenüber Forderungen des Betreibers auch berechtigt, wenn
          der Nutzer Gegenansprüche aus demselben Vertrag geltend macht. Der
          Nutzer darf ein Zurückbehaltungsrecht nur dann ausüben, wenn sein
          Gegenanspruch aus demselben Vertrag herrührt.
        </p>
      </Grid>
      <Grid item xs={12}>
        <h2>10. Haftung des Betreibers</h2>
        <p>
          Bei vorsätzlicher und grob fahrlässiger Pflichtverletzung haftet der
          Betreiber – gleich aus welchem Rechtsgrund – gemäß den gesetzlichen
          Bestimmungen. Im Falle einfacher Fahrlässigkeit haftet der Betreiber
          nur für Schäden aus der Verletzung einer vertragswesentlichen Pflicht.
          Vertragswesentliche Pflichten sind solche, deren Erfüllung die
          ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglichen
          und auf deren Einhaltung der Nutzer regelmäßig vertraut und vertrauen
          darf. Im Falle der Verletzung wesentlicher Vertragspflichten ist die
          Ersatzpflicht auf den bei Vertragsschluss vorhersehbaren,
          typischerweise eintretenden Schaden begrenzt.
        </p>

        <p>
          Die Haftungsbeschränkung gilt nicht, soweit es um Schäden aus der
          Verletzung des Lebens, des Körpers oder der Gesundheit sowie um
          Ansprüche nach dem Produkthaftungsgesetz geht, oder sofern ein Mangel
          arglistig verschwiegen oder eine Garantie für die Beschaffenheit
          übernommen wurde. Die vorstehenden Haftungsausschlüsse und
          -beschränkungen gelten in gleichem Umfang zugunsten der Organe,
          gesetzlichen Vertreter, Angestellten und der sonstigen
          Erfüllungsgehilfen des Betreibers.
        </p>
      </Grid>
      <Grid item xs={12}>
        <h2>11. Datenschutz</h2>
        <p>
          Der Betreiber legt großen Wert auf einen sorgfältigen Umgang mit
          personenbezogenen Daten der Nutzer und beachtet die einschlägigen
          datenschutzrechtlichen Bestimmungen. Diesbezüglich wird auf die
          gesonderte Datenschutzerklärung verwiesen.
        </p>
      </Grid>
      <Grid item xs={12}>
        <h2>12. Änderung der Geschäftsbedingungen</h2>
        <p>
          Der Betreiber behält sich vor, diese Geschäftsbedingungen jederzeit
          mit Wirksamkeit auch innerhalb der bestehenden Vertragsverhältnisse zu
          ändern. Sofern der Betreiber eine solche Änderung dieser
          Geschäftsbedingungen beabsichtigt, wird der Betreiber den Nutzer
          hierüber mindestens sechs Wochen vor dem geplanten Inkrafttreten der
          Änderungen in Kenntnis setzen. Sofern der Nutzer nicht innerhalb von
          sechs Wochen (Widerspruchsfrist) ab Zugang der Mitteilung der Änderung
          widerspricht, gilt die Änderung ab Ablauf der Widerspruchsfrist als
          wirksam vereinbart. Der Widerspruch hat per E-Mail oder schriftlich zu
          erfolgen. Der Betreiber wird auf diese Folge in der
          Änderungsmitteilung gesondert hinweisen. Übt der Nutzer sein
          Widerspruchsrecht aus, gilt der Änderungswunsch als abgelehnt. Der
          Vertrag wird dann ohne die vorgeschlagene Änderung fortgesetzt. Das
          Recht der Vertragspartner zur Kündigung des Vertrages bleibt hiervon
          unberührt.
        </p>
      </Grid>
      <Grid item xs={12}>
        <h2>13. Schlussbestimmungen</h2>
        <p>
          Diese Geschäftsbedingungen und die gesamte Rechtsbeziehung zwischen
          dem Betreiber und dem Nutzer unterliegen ausschließlich dem Recht der
          Bundesrepublik Deutschland. Sofern der Nutzer seine Anmeldung zur
          Basismitgliedschaft oder seine Bestellung einer VIP Mitgliedschaft als
          Verbraucher im Sinne des § 13 BGB abgegeben hat und zum Zeitpunkt
          seiner Anmeldung bzw. Bestellung seinen gewöhnlichen Aufenthalt in
          einem anderen Land hat, bleibt die Anwendung zwingender
          Rechtsvorschriften dieses Landes von der in Satz 1 getroffenen
          Rechtswahl unberührt.
        </p>

        <p>
          Sollten einzelne Klauseln des Vertrages oder der Geschäftsbedingungen
          ganz oder teilweise unwirksam sein oder werden, so berührt dies die
          Wirksamkeit der übrigen Bestimmungen des Vertrages/der
          Geschäftsbedingungen nicht.
        </p>
      </Grid>
      <Grid item xs={12}>
        <h2>14. Widerrufsrecht</h2>
        <p>
          Verbrauchern im Sinne des § 13 des Bürgerlichen Gesetzbuches (BGB)
          steht ein Widerrufsrecht wie folgt zu:
        </p>

        <p>Widerrufsbelehrung</p>

        <p>Widerrufsrecht</p>

        <p>
          Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen
          diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt 14 Tage ab
          dem Tag des Vertragsschlusses.
        </p>

        <p>
          Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (lovely vibes GmbH,
          Ballindamm 27, 20095 Hamburg, Telefon: +49 40 33 46 46 26, E-Mail:
          getasugar@lovelyvibes.de) mittels einer eindeutigen Erklärung (z.B. ein
          mit der Post versandter Brief, Telefax oder E-Mail) über ihren
          Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können
          dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch
          nicht vorgeschrieben ist.
        </p>

        <p>
          Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung
          über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist
          absenden.
        </p>

        <p>Folgen des Widerrufs</p>

        <p>
          Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen,
          die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit
          Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie
          eine andere Art der Lieferung als die von uns angebotene, günstigste
          Standardlieferung gewählt haben), unverzüglich und spätestens binnen
          vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
          Ihren Widerruf dieses Vertrages bei uns eingegangen ist. Für die
          Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der
          ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
          wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden
          Ihnen wegen dieser Rückzahlung Entgelte berechnet.
        </p>

        <p>
          Haben Sie verlangt, dass die Serviceleistung während der
          Widerrufsfrist beginnen soll, so haben Sie uns einen angemessenen
          Betrag zu zahlen, der dem Anteil der bis zu diesem Zeitpunkt, zu dem
          Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses
          Vertrags unterrichten, bereits erbrachten Serviceleistungen im
          Vergleich zum Gesamtumfang der im Vertrag vorgesehenen
          Serviceleistungen entspricht.
        </p>

        <p>
          Ende der Widerrufsbelehrung <br />
          <br />
        </p>

        <p>Muster-Widerrufsformular</p>

        <p>
          (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses
          Formular aus und senden Sie es zurück.)
          <br />
          <br />
        </p>

        <p>
          - An [lovely vibes GmbH, Ballindamm 27, 20095 Hamburg, E-Mail:
          getasugar@lovelyvibes.de]: <br />
          <br />
        </p>

        <p>
          - Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen
          Vertrag über den Kauf der folgenden Waren (*)/die Einbringung der
          folgenden Serviceleistung (*) <br />
          <br />
        </p>

        <p>
          - Bestellt am (*)/erhalten am (*) <br />
          <br />
        </p>

        <p>
          - Name des/der Verbraucher(s) <br />
          <br />
        </p>

        <p>
          - Anschrift des/der Verbraucher(s)
          <br />
          <br />
        </p>

        <p>
          - Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
          <br />
          <br />
        </p>

        <p>
          - Datum
          <br />
          <br />
        </p>

        <p>(*) Unzutreffendes streichen</p>
      </Grid>
    </Grid>
  );
}
