'use client';
import lovelyvibes from 'assets/images/legal/privacyAddress.png';
import richter from 'assets/images/legal/privacyContact.png';
import { Grid, Typography } from '@mui/material';
import { Metadata } from 'next/types';

interface Props {
  companyName?: string;
}

export const metadata: Metadata = {
  title: 'Datenschutzhinweise für die Nutzung von getasugar',
  description:
    'Hier finden Sie Hinweise dazu, welche personenbezogenen Daten getasugar erhebt, wie wir mit diesen Daten umgehen und welche Entscheidungsmöglichkeiten Sie als Nutzer haben.',
  robots: {
    index: false,
    follow: false,
  },
};

export function PrivacyDeclaration(props: Props) {
  const { companyName } = props;

  return (
    <Grid
      container
      justifyContent="center"
    >
      <Grid item xs={12}>
        <Typography variant="h1" component="h1">
          Datenschutzhinweise für die Nutzung von getasugar
        </Typography>
        <p>
          Danke, dass Sie getasugar nutzen! Hier finden Sie Hinweise dazu,
          welche personenbezogenen Daten getasugar erhebt, wie wir mit diesen
          Daten umgehen und welche Entscheidungsmöglichkeiten Sie als Nutzer
          haben. Informationen über die Verarbeitung von Daten im Zusammenhang
          mit Cookies und anderen Trackingtechnologien bei Nutzung der
          getasugar-Webseite und -App finden Sie in unseren Cookies- &
          Tracking-Hinweisen.
        </p>
      </Grid>

      <Grid item xs={12}>
        <h2>Inhaltsverzeichnis</h2>
        <ul>
          <li>Kontakt / Verantwortliche Stelle</li>
          <li>Erhebung, Verarbeitung und Nutzung personenbezogener Daten</li>
          <li>Verarbeitungszwecke</li>
          <li>Rechtsgrundlage für die Verarbeitung personenbezogener Daten</li>
          <li>Weitergabe von Daten an Dritte; Dienstleister</li>
          <li>Wie schützen wir Ihre Daten?</li>
          <li>Dauer der Speicherung; Aufbewahrungspflichten</li>
          <li>Betroffenenrechte im Überblick</li>
          <li>Social Media</li>
          <li>Cookies- & Tracking-Hinweisen</li>
        </ul>
      </Grid>
      <Grid item xs={12}>
        <h2>Kontakt / Verantwortliche Stelle</h2>
        <p>
          Sofern in diesen Datenschutzhinweisen oder in unseren Cookies- &
          Tracking-Hinweisen nicht Abweichendes angegeben, ist verantwortliche
          Stelle für die Datenverarbeitung die lovely vibes GmbH (im folgenden
          „getasugar“ oder „wir“ bzw. „uns“).
        </p>

        <p>
          Wenn Sie mit uns in Kontakt treten möchten, erreichen Sie uns wie
          folgt:
        </p>

        <p>
          <img src={lovelyvibes.src} alt="Adresse" width="212" />
          <br />
          Email: getasugar@lovelyvibes.de
        </p>

        <p>Unseren Datenschutzbeauftragten erreichen Sie unter:</p>

        <p>
          <img src={richter.src} alt="Adresse" width="203" />
        </p>

        <p>
          Für Auskünfte und Anregungen zum Thema Datenschutz stehen wir bzw.
          unserer Datenschutzbeauftragter Ihnen gerne zur Verfügung.
        </p>
      </Grid>

      <Grid item xs={12}>
        <h2>Erhebung, Verarbeitung und Nutzung personenbezogener Daten</h2>
        <p>
          Personenbezogene Daten sind alle Informationen, die sich auf eine
          identifizierte oder identifizierbare natürliche Person beziehen (z. B.
          Name, Anschrift, Telefonnummer, Geburtsdatum oder E-Mail-Adresse).
        </p>

        <p>
          Wenn wir personenbezogene Daten verarbeiten, bedeutet dies, dass wir
          diese Daten z. B. erheben, speichern, nutzen, an andere übermitteln
          oder löschen.
        </p>

        <p>
          getasugar erhebt und verarbeitet Ihre personenbezogenen Daten
          ausschließlich in den folgenden Fällen:
        </p>

        <ul>
          <li>
            Wenn Sie uns auf unserer Webseite besuchen, ohne Mitglied zu sein.
          </li>
          <li>Wenn Sie uns auf direktem Wege kontaktieren.</li>
          <li>Wenn Sie unsere App installieren.</li>
          <li>
            Wenn Sie eine unentgeltliche bzw. entgeltliche Mitgliedschaft bei
            getasugar abschließen und anschließend getasugar nutzen.
          </li>
        </ul>

        <p>
          Die Bereitstellung Ihrer personenbezogenen Daten im Rahmen des
          Abschlusses und Bestehens des Vertragsverhältnisses mit getasugar
          erfolgt freiwillig. Für die Mitgliedschaft bei getasugar ist es aber
          erforderlich, dass Sie personenbezogene Daten angeben sowie Fragen zu
          Ihrem getasugar-Profil beantworten. Auch setzt die Nutzung des
          getasugar-Service voraus, dass Ihre Profilinformationen anderen
          getasugar-Mitgliedern gegenüber ersichtlich sind.
        </p>
        <p>
          Bitte beachten Sie in diesem Zusammenhang auch, dass wir den
          getasugar-Service künftig in verschiedenen Ländern anbieten (Sie
          können die Liste dieser Länder auf unserer Webseite einsehen) und die
          in Ihrem Profil enthaltenen Informationen für unsere Mitglieder in
          allen diesen Ländern sichtbar sind.
        </p>

        <p>
          Möchten Sie nicht, dass getasugar von Ihnen personenbezogene Daten
          oder Teile hiervon verarbeitet, so kann getasugar seinen Service, wie
          in den Allgemeinen Geschäftsbedingungen aufgeführt, nicht erbringen.
        </p>

        <p>
          Welche Daten getasugar im Einzelnen erhebt, entnehmen Sie bitte den
          nachfolgenden Ausführungen.
        </p>

        <h3>Datenverarbeitung durch App-Stores</h3>

        <p>
          Installieren Sie die App über einen App-Store-Betreiber als
          Drittanbieter (z. B. Google oder Apple), müssen Sie ggf. mit dem
          jeweiligen App-Store-Betreiber eine Nutzungsvereinbarung über den
          Zugang zu dessen Portal abschließen. Dieser Zugang setzt ein gültiges
          Konto bei dem App-Store-Betreiber sowie ein entsprechendes Endgerät
          (z. B. Smartphone) voraus und getasugar hat keinen Einfluss auf die
          Datenverarbeitung in diesem Zusammenhang. Verantwortliche Stelle ist
          insoweit allein der App-Store-Betreiber der jeweiligen App-Plattform.
          Bitte informieren Sie sich ggf. direkt bei dem App-Store-Betreiber
          (Drittanbieter) über dessen Datenverarbeitung. In diesem Fall ist
          getasugar erst im Rahmen der Nutzung der App verantwortliche Stelle.
        </p>

        <h3>
          Welche Daten erhebt getasugar von seinen Mitgliedern zur Erfüllung des
          Vertragsverhältnisses?{' '}
        </h3>

        <p>
          Zur Erbringung der in den Allgemeinen Geschäftsbedingungen und den
          Produktbeschreibungen näher beschriebenen Leistungen durch getasugar
          ist es erforderlich, die nachfolgend beschriebenen personenbezogene
          Daten, die der Nutzer (auch als getasugar-Mitglied bezeichnet) unter
          anderem im Rahmen des Registrierungsprozesses, des Abschlusses einer
          Basis-Mitgliedschaft oder einer VIP-Mitgliedschaft angibt, zu
          verarbeiten.
        </p>

        <h3>Registrierungsprozess</h3>

        <p>
          Bei der zunächst kostenlosen Registrierung (Abschluss der sogenannten
          „Basis-Mitgliedschaft“) wird der Nutzer (oder auch „Basis-Mitglied“
          genannt) aufgefordert, bestimmte Mindestangaben zu machen, ohne die
          die Anmeldung nicht abgeschlossen werden kann. Diese Daten sind:
        </p>

        <ul>
          <li>Eigenes Geschlecht</li>
          <li>E-Mail-Adresse</li>
          <li>Geburtsdatum</li>
          <li>Von dem Nutzer gewähltes Passwort</li>
        </ul>

        <p>
          Der jeweilige Zugang zu getasugar erfolgt sodann über die von dem
          Nutzer angegebene E-Mail-Adresse und das von ihm ausgesuchte Passwort
          (sogenannte Zugangsdaten).
        </p>

        <h3>Optionale Angaben im Rahmen einer Basis-Mitgliedschaft </h3>

        <p>
          Nach dem Registrierungsprozess kann der Nutzer sein Profil optional
          u.a. um folgende Angaben erweitern
        </p>

        <ul>
          <li>Größe</li>
          <li>Interessen</li>
          <li>Sprachen</li>
        </ul>

        <p>
          Ferner ist es möglich das Profil um weitere optionale Angaben zu
          erweitern, die als Grundlage für die Erstellung des getasugar-Profils
          dienen. Auch können Fotos des getasugar-Mitglieds hinterlegt werden,
          wobei diese Fotos anderen Mitgliedern in kenntlicher Form im Profil
          oder per E-Mail neben dem Vornamen oder einem anonymen Wunschnamen des
          Mitglieds zur Verfügung gestellt werden.
        </p>

        <p>
          Alle Daten des getasugar-Profils sind auf der Webseite und App für
          andere angemeldete Nutzer sichtbar. Insbesondere sind sichtbar der
          Wohnort, das Alter.
        </p>

        <h3>Abschluss einer VIP-Mitgliedschaft </h3>

        <p>
          Entscheidet sich der Nutzer (in Folge auch „VIP-Mitglied“ genannt),
          eine kostenpflichtige Leistung von getasugar (sogenannte
          „VIP-Mitgliedschaft“) über getasugar abzuschließen, so werden
          innerhalb der Bestellung folgende Angaben zusätzlich zu den Daten, die
          bei der Basis-Mitgliedschaft erhoben werden, erhoben:
        </p>

        <ul>
          <li>Name und Vorname</li>
          <li>Wohnadresse</li>
          <li>
            Zahlungs- und Rechnungsdaten (Kreditkartendaten, PayPal- und
            SEPA-Daten werden von dem Payment Provider und nicht von getasugar
            gespeichert).
          </li>
        </ul>

        <h3>Bestellung von virtuellen Gütern</h3>

        <p>
          Entscheidet sich der Nutzer seine Basis-Mitgliedschaft durch die
          Bestellung von virtuellen Gütern (z.B. Coins) zu erweitern, so werden
          innerhalb der Bestellung zusätzlich zu den Daten, die bei der
          Basis-Mitgliedschaft erhoben werden, folgende Angaben erhoben:
        </p>

        <ul>
          <li>
            Zahlungsdaten (Kreditkartendaten, Paypal- und SEPA-Daten werden von
            dem Payment Provider und nicht von getasugar gespeichert).
          </li>
          <li>Name und Vorname</li>
          <li>Wohnadresse</li>
          <li>
            Zahlungs- und Rechnungsdaten (Kreditkartendaten, PayPal- und
            SEPA-Daten werden von dem Payment Provider und nicht von getasugar
            gespeichert).
          </li>
        </ul>

        <h3>Bestellungen über Drittanbieter</h3>

        <p>
          Im Falle der Bestellung einer VIP-Mitgliedschaft oder virtueller Güter
          über einen App-Store-Betreiber (z. B. Google oder Apple) als
          Drittanbieter ist den Nutzungsbedingungen dieser
          App-Plattform-Betreiber zu entnehmen, welche personenbezogenen Daten
          von diesem im Zuge des Kaufprozesses verarbeitet werden. getasugar
          verarbeitet insoweit keine Zahlungs- und Rechnungsdaten.
        </p>

        <p>
          Kommunikation mit den Mitgliedern untereinander, Kommunikation mit dem
          Kundenservice und Kommunikation per Videochat
        </p>

        <p>
          getasugar speichert die Kommunikation der Mitglieder, die über die
          getasugar-Plattform erfolgt, sowie die Kommunikation mit dem
          Kundenservice. Die Kommunikation der Mitglieder untereinander im
          eingeloggten Zustand ist verschlüsselt.
        </p>

        <p>
          Bitte berücksichtigen Sie in Bezug auf die Kommunikation mit unserem
          Kundenservice Folgendes: Kommunizieren Sie mit unserem Kundenservice
        </p>

        <ul>
          <li>
            mit Hilfe des Kontaktformulars, so ist diese Übertragung
            verschlüsselt; unsere Beantwortung Ihrer Anfrage ist
            transportverschlüsselt, sofern Ihr Mailprovider die Transport Layer
            Security (TLS) Transportverschlüsselung unterstützt;
          </li>

          <li>
            über E-Mailversand, so ist die Übertragung der E-Mails
            transportverschlüsselt, sofern Ihr Mailprovider die (TLS)
            Transportverschlüsselung unterstützt.
          </li>

          <li>
            sofern bereitgestellt, via Chat-Funktion für eingeloggte Mitglieder
            im Customer Service Portal, so ist die Übertragung der
            Chatkommunikation ebenfalls TLS-verschlüsselt
            (Transportverschlüsselung). Wird die Chat-Funktion mit dem
            Kundenservice genutzt, besteht die Möglichkeit ein entsprechendes
            Chat-Protokoll zur Verfügung zu stellen.
          </li>
        </ul>

        <p>
          Wünschen Sie eine E-Mailkommunikation mit Inhaltverschlüsselung, so
          bitten wir Sie, getasugar stattdessen per Post oder per Fax zu
          kontaktieren. Unsere Adressdaten finden Sie unter Kontakt.
        </p>

        <p>
          Achten Sie bitte darauf, dass grundsätzlich die Kommunikation zwischen
          getasugar und Ihnen nur über die bei getasugar hinterlegte
          E-Mail-Adresse erfolgt. Mitglieder, die sich über Apple registriert
          und ihre E-Mail-Adresse verborgen haben, erhalten die Kommunikation
          nur, sofern die Funktion der automatischen Weiterleitung bei Apple
          aktiviert ist. Haben Sie bitte Verständnis dafür, dass Apple uns nicht
          mitteilt, ob Sie die Weiterleitungsfunktion aktiviert haben.
        </p>

        <h3>E-Mails – Newsletter – Nachrichten</h3>
        <p>
          Mit Abschluss der unentgeltlichen Basis-Mitgliedschaft geben Sie bei
          Registrierung Ihre E-Mail-Adresse an bzw. erhalten wir diese von Apple
          / Facebook (im Falle der hierüber erfolgten Registrierung). Diese
          E-Mail-Adresse bzw. etwaig in Folge von Ihnen neu angegebene
          E-Mail-Adressen werden für den Versand von E-Mails und Newslettern für
          die unentgeltlichen und entgeltlichen Produkte der lovely vibes GmbH
          verwendet, ohne dass es einer Einwilligung Ihrerseits bedarf.
        </p>
        <p>
          Werbe-E-Mails (inkl. Newsletter und Ratgeber) sind
          transportverschlüsselt, sofern Ihr Mailprovider die „Transport Layer
          Security“ (TLS) Transportverschlüsselung unterstützt.
        </p>

        <p>
          Wenn Sie E-Mails und Newsletter von getasugar nicht mehr erhalten
          möchten, haben Sie jederzeit die Möglichkeit, der Verwendung Ihrer im
          Profil hinterlegten E-Mail-Adresse für solche Zwecke zu widersprechen:
          (1) Klicken Sie hierfür auf den Abmeldelink am Ende der jeweiligen
          E-Mail oder (2) passen Sie in Ihrem Profil Ihre Benachrichtigungen wie
          gewünscht an oder (3) kontaktieren Sie den getasugar-Kundenservice.
          Hiervon ausgenommen sind wichtige Systemmails, wie zum Beispiel das
          Neu-Setzen eines Passwortes.
        </p>

        <h3>Push-Nachrichten</h3>

        <p>
          Wenn Ihr Mobilgerät oder Browser es ermöglicht, erhalten Sie im Rahmen
          der Nutzung der App oder der Plattform sogenannte Push-Nachrichten von
          uns, und zwar auch, wenn Sie diese App oder Plattform gerade nicht
          nutzen. Dabei handelt es sich um Nachrichten, die wir Ihnen im Rahmen
          der Vertragserfüllung zusenden, aber auch um werbliche Informationen.
        </p>

        <p>
          Sie können den Erhalt von Push-Nachrichten jederzeit über die
          Geräteeinstellungen Ihres mobilen Endgerätes oder in der App oder in
          den Einstellung Ihres Browsers oder innerhalb der Plattform anpassen
          oder unterbinden.
        </p>

        <h3>
          Welche Daten erhebt getasugar bei Nutzung der getasugar-Plattform?
        </h3>

        <p>
          Bei jedem Zugriff auf die getasugar Plattform werden Nutzungsdaten der
          jeweiligen Webseitenbesucher – soweit nachstehend nicht anders
          gekennzeichnet, auch soweit es sich um keine getasugar-Mitglieder
          handelt – durch den jeweiligen Internet-Browser übermittelt und in
          Protokolldateien, sogenannten Server-Logfiles, gespeichert. Diese
          Daten sind:
        </p>

        <ul>
          <li>
            Informationen über den Browsertyp und den Internet-Service Provider
            des Nutzers
          </li>
          <li>
            IP-Adresse (Internet-Protokoll-Adresse) des zugreifenden Rechners,
            Tablets oder Smartphones (Hierbei wird die IP-Adresse auch mit einer
            Geo-Datenbank abgeglichen und die Herkunft bzw. das Land, Bundesland
            und die Stadt des Nutzers ermittelt).
          </li>
          <li>Name der abgerufenen Seite</li>
          <li>Datum und Uhrzeit des Abrufs</li>
          <li>
            die Referrer-URL (Herkunfts-URL), von der der Nutzer auf die
            abgerufene Seite gekommen ist
          </li>
          <li>Übertragene Datenmenge</li>
          <li>Statusmeldung darüber, ob der Abruf erfolgreich war</li>
          <li>Sitzungs-Identifikationsnummer (Session ID)</li>
          <li>Sitzungsteilnehmer-Pseudonym</li>
          <li>
            Nutzerkennung (User-ID) auf der getasugar-Plattform (nur von
            getasugar-Mitgliedern)
          </li>
          <li>Verwendete Bildschirmauflösung</li>
          <li>Operating System</li>
          <li>Landingpage</li>
        </ul>

        <p>
          In den sogenannten Login-Datensätzen wird zusätzlich jedes Mal, wenn
          das getasugar-Mitglied sich ins Netzwerk einloggt, die Nutzerkennung
          auf der getasugar-Plattform gespeichert.
        </p>

        <p>
          Neben den zuvor genannten Daten können bei der Nutzung der
          getasugar-Webseite Cookies oder pseudonyme IDs (wie ggf. User-ID,
          Ad-ID) während oder auch nach Ihrem Besuch unserer Onlineangebote auf
          Ihrem Endgerät gespeichert werden. Nähere Informationen in diesem
          Zusammenhang finden Sie in unseren Cookies- & Tracking-Hinweisen.
        </p>

        <h3>Welche Daten erhebt getasugar bei Nutzung der getasugar-App?</h3>

        <p>
          Bei jedem Aufruf der App durch das Endgerät des Nutzers erfasst
          getasugar automatisiert Daten und Informationen vom Betriebssystem des
          aufrufenden Gerätes. Hierzu gehört u.a. auch die Speicherung der
          IP-Adresse. Im Einzelnen erfasst getasugar:
        </p>

        <h3>Nutzungsdaten</h3>

        <p>
          Bei jedem Zugriff auf die App werden Nutzungsdaten der jeweiligen
          App-Nutzer gespeichert. Diese Daten sind:
        </p>
        <ul>
          <li>Das verwendete Betriebssystem und ggf. der Browsertyp</li>
          <li>Aktuelle Spracheinstellung des Smartphones</li>
          <li>Informationen über den Internet-Service Provider des Nutzers</li>
          <li>
            IP-Adresse (Internet-Protokoll-Adresse) des zugreifenden Rechners
          </li>
          <li>
            Geräte ID (z. B. UDID, IDFV) zur Identifikation Ihres Geräts/Ihrer
            Geräte im Rahmen einer sicheren Authentifizierung (Secure
            Authentification)
          </li>
          <li>
            Nutzerkennung auf der getasugar-Plattform (nur von
            getasugar-Mitgliedern)
          </li>
          <li>Name der abgerufenen Seite und ggf. die Herkunfts-Seite</li>
          <li>Datum und Uhrzeit des Abrufs</li>
          <li>Übertragene Datenmenge</li>
          <li>Statusmeldung darüber, ob der Abruf erfolgreich war</li>
        </ul>

        <p>
          Neben den zuvor genannten Daten können bei der Nutzung der App
          Trackingtechnologien oder pseudonyme IDs (wie ggf. Ihre Werbe-ID (z.B.
          Apples IDFA (Identifier für Werbetreibende), Googles Advertising-ID)
          während oder auch nach Ihrem Besuch unserer App von Ihrem Endgerät
          abgerufen werden. Nähere Informationen in diesem Zusammenhang finden
          Sie in unseren Cookies- & Tracking-Hinweisen.
        </p>

        <h3>Login-Daten</h3>

        <p>
          In den sogenannten Login-Datensätzen werden jedes Mal, wenn das
          getasugar-Mitglied sich ins Netzwerk einloggt, folgende Nutzungsdaten
          gespeichert:
        </p>

        <ul>
          <li>Datum und Uhrzeit des Logins</li>
          <li>Nutzerkennung auf der getasugar-Plattform (E-Mail-Adresse)</li>
          <li>IP-Adresse (Internet-Protokoll-Adresse)</li>
          <li>
            Geräte-ID (z. B. UDID) zur Identifikation Ihres Geräts/Ihrer Geräte
            im Rahmen einer sicheren Authentifizierung (Secure Authentification)
          </li>
        </ul>

        <p>
          Neben den zuvor genannten Daten können bei der Nutzung der App
          pseudonyme IDs (wie ggf. User-ID, Ad-ID) während oder auch nach Ihrem
          Besuch auf Ihrem Endgerät gespeichert werden. Nähere Informationen in
          diesem Zusammenhang finden Sie in unseren Cookies- &
          Tracking-Hinweisen.
        </p>

        <h3>GPS-Daten bei Nutzung der getasugar-App</h3>

        <p>
          Nutzt ein VIP-Mitglied die Einstellungsmöglichkeiten für die
          Umkreissuche, wird in diesem Augenblick auf die Standortdaten des
          Geräts zugegriffen (GPS, ggf. WLAN Informationen, und Geräte ID). Mit
          diesen Informationen wird der Standort des Nutzers ermittelt, damit er
          einen passenden Suchradius einstellen kann.
        </p>
      </Grid>
      <Grid item xs={12}>
        <h2>Verarbeitungszwecke </h2>
        <p>
          getasugar verarbeitet die personenbezogenen Daten seiner Nutzer zu
          nachfolgenden Zwecken und auf Basis der im Folgenden aufgeführten
          Rechtsgrundlagen. Sofern die Datenverarbeitung auf der Rechtsgrundlage
          eines berechtigten Interesses beruht, erläutern wir Ihnen in Folge
          auch unser berechtigtes Interesse an der Datenverarbeitung:
        </p>

        <ul>
          <li>
            Zur Bereitstellung der getasugar Plattform und damit zur Erfüllung
            der Leistungen, die in den Allgemeinen Geschäftsbedingungen
            beschrieben sind. Dies beinhaltet insbesondere:
          </li>
          <li>
            Bereitstellung unserer Webseite für nicht registrierte Nutzer;
          </li>
          <li>
            Bereitstellung und Nutzung unserer Webseite und App für registrierte
            getasugar-Mitglieder;
          </li>
          <li>
            Bereitstellung einer Kontaktmöglichkeit und Beantwortung von
            Anfragen über die Plattform;
          </li>
          <li>Ermöglichen des Abschlusses eines Vertrags;</li>
          <li>
            Versand von E-Mails und/oder Push-Nachrichten (Rechtsgrundlage der
            Verarbeitung ist die Erfüllung des Vertrags mit Ihnen.
            Rechtsgrundlage ist in einigen Fällen auch Ihre Einwilligung.
            Rechtsgrundlage ist auch unsere gesetzliche Pflicht sowie das
            berechtigte Interesse, einen Kundenservice anzubieten und das
            Kundenerlebnis zu verbessern);
          </li>
          <li>
            Für sog. A/B-Testing. Rechtsgrundlage für die Nutzung der
            A/B-Testings ist das berechtigte Interesse getasugars. Dieses
            berechtigte Interesse besteht in der Verbesserung der
            Nutzerfreundlichkeit und Attraktivität unserer Webseite.
          </li>
          <li>
            Zur Missbrauchsprävention und -abwehr. getasugar erhebt, verarbeitet
            und nutzt personenbezogene Daten sowie Geodaten, die im Zusammenhang
            mit Ihrer Registrierung und des Ausfüllens des
            getasugar-Persönlichkeitstests und/oder des getasugar-Profils
            erhoben werden, für eine automatisierte Prüfung, ob Anhaltspunkte
            oder Daten für einen Missbrauch von getasugar bestehen. Die Daten
            werden dabei in einer Datenbank gespeichert und mit Erfahrungswerten
            abgeglichen. Ergibt die automatisierte Verarbeitung einen
            Missbrauchsverdacht, überprüft ein Mitarbeiter von getasugar die
            Bewertung und die zugrundeliegenden Anhaltspunkte sowie im Anschluss
            etwaige Freitextangaben und die hinterlegten Profilfotos. Auch
            stellt getasugar seinen Mitgliedern eine Funktion mit Namen „Profil
            melden“ zur Verfügung und nimmt Überprüfungen von Profilen durch
            Mitarbeiter des Kundenservices bei durch andere Mitglieder
            gemeldeten Vertragsverstößen vor. Ferner nutzt getasugar die
            freiwillige Angabe der Handynummer, um im Rahmen einer sogenannten
            SMS-Verifizierung einen Handy-gestützten Identitätsabgleich
            vorzunehmen. (Rechtsgrundlage ist das berechtigte Interesse
            getasugars und der getasugar-Mitglieder, dass der getasugar-Service
            nicht für vertrags- und / oder rechtswidrige Handlungen Dritter
            missbraucht wird. Wir kommen dabei auch unserer rechtlichen
            Verpflichtung im Bereich Datensicherheit nach, die Systemsicherheit
            und die Aufdeckung und Nachverfolgung unzulässiger Zugriffsversuche
            bzw. Zugriffe zu gewährleisten.)
          </li>
          <li>
            Ferner für Zwecke der Datensicherheit (insb. Verfügbarkeit
            entsprechend der Pflichten aus Art. 5 Abs. 1 lit. f, Art. 32 DSGVO).
            (Rechtsgrundlage ist das berechtigte Interesse getasugars an der
            Gewährleistung der Datensicherheit und der getasugar-Mitglieder,
            dass der getasugar-Service nicht für vertrags- und / oder
            rechtswidrige Handlungen Dritter missbraucht wird. Wir kommen dabei
            auch unserer rechtlichen Verpflichtung im Bereich Datensicherheit
            nach, die Systemsicherheit und die Aufdeckung und Nachverfolgung
            unzulässiger Zugriffsversuche bzw. Zugriffe zu gewährleisten.)
          </li>
          <li>
            Für personalisierte Angebote (z.B. die automatisierte
            Preisermittlung). Wir nutzen grundsätzlich keine vollautomatisierte
            Entscheidungsfindung gemäß Art. 22 DSGVO, sondern verarbeiten Ihre
            Daten lediglich teilweise automatisiert mit dem Ziel, Ihnen passende
            Produktangebote anbieten zu können. Dafür dienen folgende Daten:
            Alter, Geschlecht, Einkommen, Marketing-Channel, Registrierungsgerät
            (App oder Webseite). (Rechtsgrundlage ist die Erfüllung des
            Vertragsverhältnisses sowie unser berechtigtes Interesse, unsere
            Nutzer gezielt und individuell anzusprechen.)
          </li>
          <li>
            Für die Sicherstellung Ihrer Vergütungspflichten aus dem
            Vertragsverhältnis im Falle des Verzuges. Sollten Sie offene
            Rechnungen / Teilzahlungen trotz wiederholter Mahnung nicht
            begleichen, übermitteln wir die für die Durchführung eines
            Inkassoverfahrens erforderlichen Daten an einen Inkassodienstleister
            zum Zweck des Treuhandinkasso. Soweit es zu streitigen
            Zahlungsforderungen kommt, übermitteln wir zudem die erforderlichen
            entsprechenden Daten an unsere Zahlungsdienstleister für die
            Geltendmachung unserer Rechte (bspw. bei strittigen
            Zahlungsverpflichtungen, bei Anfragen von Banken und/oder im Rahmen
            einer Konfliktlösung bei Chargebacks). (Rechtsgrundlage ist neben
            der Erfüllung des Vertragsverhältnisses auch das berechtigte
            Interesse getasugars an der Zahlung der vertraglich vereinbarten
            Vergütung.)Für die Wahrung und Verteidigung unserer Rechte sowie die
            Erfüllung rechtlicher Verpflichtungen. (Rechtsgrundlage ist neben
            der Erfüllung einer rechtlichen Verpflichtung unser berechtigtes
            Interesse an der Geltendmachung und Verteidigung unserer Rechte.)
          </li>
          <li>
            Zum Betrieb unserer Webseite, Apps sowie zur Verfügungstellung von
            den Nutzern ausdrücklich gewünschten Telemediendienen
            (Rechtsgrundlage ist § 25 Abs. 2 Nr. 2 TTDSG).
          </li>
          <li>
            Zur Eigenwerbung durch Werbe-E-Mails und/oder Push-Nachrichten,
            Newsletter, (Nutzer-)Umfragen und individualisierte Ratgeber
            (ausschließlich Produkte der lovely vibes GmbH). (Rechtsgrundlage
            ist das berechtigte Interesse der lovely vibes GmbH an
            Direktmarketing für eigene Produkte sowie § 7 III UWG.
            Rechtsgrundlage ist in einigen Fällen auch Ihre Einwilligung.)
          </li>
          <li>
            Zum Zwecke der Berücksichtigung des Werbewiderspruchs.
            (Rechtsgrundlage ist die Erfüllung unserer gesetzlichen
            Verpflichtungen.)
          </li>
          <li>
            Für die Einhaltung gesetzlicher Aufbewahrungspflichten und anderer
            gesetzlicher oder rechtlicher Verpflichtungen und Bestimmungen (z.B.
            im Zusammenhang mit Steuerprüfungen, behördlichen oder gerichtlichen
            Auskunfts- und sonstigen Anordnungen). (Rechtsgrundlage ist die
            Erfüllung unserer gesetzlichen Verpflichtungen.)
          </li>
          <li>
            Für sonstige Kommunikationszwecke im Rahmen von Anfragen.
            (Rechtsgrundlage kann ein vorvertragliches Rechtsverhältnis oder
            eine gesetzliche Verpflichtung sein.)
          </li>
          <li>
            Um Ihre Erfolgsgeschichte bzw. Ihren Produkterfahrungsbericht
            (Erfolgsgeschichte) auf der getasugar-Webseite und ggfs. auch in
            anderen Medien zu veröffentlichen. (Rechtsgrundlage ist Ihre
            Einwilligung.)
          </li>
          <li>
            Ggf. für im öffentlichen Interesse liegende Archivzwecke, für
            wissenschaftliche oder historische Forschungszwecke oder für
            statistische Zwecke gemäß Artikel 89 Absatz 1 (Rechtsgrundlage ist
            in diesen Fällen Art. 9 Absatz 2 lit. j DSGVO bzw. Art. 6 Abs. 1 S.
            1 lit. e DSGVO i.V.m. Art. 6 Abs. 2 DSGVO; § 28 BDSG)
          </li>
        </ul>

        <p>
          Die Verarbeitungszwecke und die Rechtsgrundlage für die Verarbeitung
          personenbezogener Daten unter Verwendung von „Cookies“ und anderen
          Trackingtechnologien bei Nutzung von getasugar erfahren Sie in unseren
          Cookies- & Tracking-Hinweisen.
        </p>
      </Grid>
      <Grid item xs={12}>
        <h2>Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h2>
        <ul>
          <li>
            Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine
            Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1 S.
            1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.
          </li>
          <li>
            Soweit wir besondere Kategorien von Daten nach Art. 9 Abs. 1 DSGVO
            verarbeiten, dient Art. 9 Abs. 2 lit. a DSGVO – Ihre Einwilligung –
            als Rechtsgrundlage.
          </li>
          <li>
            Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung
            des Vertragsverhältnisses von getasugar erforderlich sind
            (Basis-Mitgliedschaft oder einer VIP-Mitgliedschaft), dient Art. 6
            Abs. 1 S. 1 lit. b DSGVO als Rechtsgrundlage. Dies gilt auch für
            Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher
            Maßnahmen erforderlich sind.
          </li>
          <li>
            Bei der Verarbeitung von personenbezogenen Daten zur Erfüllung einer
            Rechtspflicht dient Art. 6 Abs. 1 S. 1 lit. c DSGVO als
            Rechtsgrundlage.
          </li>
          <li>
            Ist die Verarbeitung zur Wahrung eines berechtigten Interesses von
            getasugar oder eines Dritten wie beispielsweise seiner Mitglieder
            erforderlich und überwiegen die Interessen, Grundrechte und
            Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so
            dient Art. 6 Abs. 1 S. 1 lit. f DSGVO als Rechtsgrundlage für die
            Verarbeitung.
          </li>
          <li>
            Sofern wir Informationen in Ihrer Endeinrichtung speichern oder auf
            solche Informationen zugreifen, die bereits in Ihrer Endeinrichtung
            gespeichert sind, dient § 25 Abs. 1 TTDSG als Rechtsgrundlage.
          </li>
          <li>
            Soweit die Speicherung von Informationen in der Endeinrichtung des
            Endnutzers oder der Zugriff auf bereits in der Endeinrichtung des
            Endnutzers gespeicherte Informationen unbedingt erforderlich ist,
            dient § 25 Abs. 2 Nr. 2 TTDSG als Rechtsgrundlage.
          </li>
          <li>
            Für die weitere Datenverarbeitung greift Art. 6 Abs. 1 DSGVO je nach
            Datenverarbeitungskonstellation in seinen einschlägigen Littera.
          </li>
          <li>
            Bei der Verarbeitung von personenbezogenen Daten, die für
            Eigenwerbung erforderlich sind (Basis-Mitgliedschaft oder
            VIP-Mitgliedschaft), ist neben Art. 6 Abs. 1 S. 1 lit. f DSGVO auch
            § 7 III UWG Rechtsgrundlage.
          </li>
          <li>
            Bei der möglichen Verarbeitung für im öffentlichen Interesse
            liegende Archivzwecke, für wissenschaftliche oder historische
            Forschungszwecke oder für statistische Zwecke gemäß Artikel 89
            Absatz 1 ist Art. 9 Absatz 2 lit. j DSGVO bzw. Art. 6 Abs. 1 S. 1
            lit. e DSGVO i.V.m. Art. 6 Abs. 2 DSGVO; § 28 BDSG die
            Rechtsgrundlage
          </li>
        </ul>

        <p>
          Sofern Rechtsgrundlage Ihre Einwilligung ist, sind Sie berechtigt,
          Ihre Einwilligung jederzeit zu widerrufen, ohne dass die
          Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
          erfolgten Verarbeitung berührt wird. Ist Rechtsgrundlage das
          berechtigte Interesse, so sind Sie ebenfalls grundsätzlich berechtigt,
          aus Gründen, die sich aus ihrer besonderen Situation ergeben,
          jederzeit gegen die Verarbeitung Sie betreffender personenbezogener
          Daten Widerspruch einzulegen. Es gilt insofern Art. 21 DSGVO.
        </p>
      </Grid>
      <Grid item xs={12}>
        <h2>Weitergabe von Daten an Dritte; Dienstleister</h2>
        <p>
          Ihre personenbezogenen Daten werden von getasugar grundsätzlich nur
          dann an Dritte weitergegeben, soweit dies zur Vertragserfüllung
          erforderlich ist, wir oder der Dritte ein berechtigtes Interesse an
          der Weitergabe haben, Ihre Einwilligung hierfür vorliegt oder dies zur
          Erfüllung einer rechtlichen Verpflichtung erforderlich ist. Sofern
          personenbezogene Daten an Dritte auf Basis eines berechtigten
          Interesses übermittelt werden, erläutern wir unser berechtigtes
          Interesse in diesen Datenschutzhinweisen. Darüber hinaus können
          personenbezogene Daten an Dritte übermittelt werden, und zwar:
        </p>
        <ul>
          <li>
            an Dienstleister, sofern die Daten zur Ausführung eines
            Auftragsverarbeitungsvertrages mit uns nötig sind;
          </li>
          <li>
            ggf. an Anbieter von Marketing-Trackingtechnologien und Analysetools
            (mehr Informationen darüber erfahren Sie in unseren Cookies- &
            Tracking-Hinweisen);
          </li>
          <li>
            wenn wir hierzu aufgrund gesetzlicher Vorgaben oder durch
            vollstreckbare behördliche oder gerichtliche Anordnung im Einzelfall
            verpflichtet sein sollten;
          </li>
          <li>
            im Zusammenhang mit Rechtsstreitigkeiten (gegenüber Gerichten oder
            unseren Anwälten), Inkassofällen oder Wirtschafts- bzw.
            Betriebsprüfungen (gegenüber Wirtschaftsprüfern /Betriebsprüfern);
          </li>
          <li>
            im Zusammenhang mit möglichen strafbaren Handlungen an die
            zuständigen Ermittlungsbehörden;
          </li>
          <li>
            im Falle eines Verkaufs des Geschäftsbetriebs (gegenüber dem
            Erwerber).
          </li>
          <li>
            Ggf. andere (soziale) Medien (wie Instagram, bei
            Instagram-Erfolgsgeschichte entsprechend erteilter Einwilligung)
          </li>
        </ul>
        <p>
          Sofern Daten regelmäßig an weitere Dritte übermittelt werden können,
          wird dies in diesen Datenschutzhinweisen bzw. in unseren Cookies- &
          Tracking-Hinweisen erläutert. Bei einer Übermittlung auf Basis einer
          Einwilligung kann die Erläuterung auch bei Einholung der Einwilligung
          erfolgen.
        </p>

        <h3>Dienstleister</h3>

        <p>
          getasugar behält sich vor, bei der Erhebung bzw. Verarbeitung von
          personenbezogenen Daten Dienstleister einzusetzen. Dienstleister
          erhalten von getasugar nur die personenbezogenen Daten, die sie für
          ihre konkrete Tätigkeit benötigen.
        </p>

        <p>
          getasugar setzt – soweit Dienstleister in diesen Datenschutzhinweisen
          bzw. in unseren Cookies- & Tracking-Hinweisen nicht bereits namentlich
          benannt sind – u.a. Dienstleister für das Versenden von E-Mails,
          Push-Nachrichten und Newsletter an die Mitglieder ein. Ferner stellen
          Dienstleister getasugar Serverkapazitäten zur Verfügung. Sofern Sie
          einen Kauf über getasugar getätigt haben, unterstützen externe
          Bezahldienste und Dienstleister getasugar bei der Abwicklung der
          Zahlungen und im Inkassofall. Je nachdem, welchen Zahlungsweg Sie im
          Bestellprozess auf der Kaufseite auswählen, gibt getasugar die zur
          Abwicklung von Zahlungen erhobenen Daten (z. B. Bankverbindung) an das
          mit der Zahlung beauftragte Kreditinstitut oder an von getasugar
          beauftragte Zahlungsdienstleister weiter. Zum Teil erheben die
          Zahlungsdienstleister diese Daten auch selbst in eigener
          Verantwortung. Es gilt insoweit die Datenschutzerklärung des
          jeweiligen Zahlungsdienstleisters.
        </p>

        <p>
          Dienstleister werden in der Regel als sogenannte Auftragsverarbeiter
          eingebunden, die personenbezogene Daten der Nutzer dieses
          Online-Angebots nur nach Weisungen von getasugar verarbeiten dürfen.
        </p>

        <p>
          Bitte beachten Sie, dass im Falle des Kaufes der
          getasugar-VIP-Mitgliedschaft über App-Store-Betreiber als
          Drittanbieter (wie z.B. Apple oder Google Play) keine
          Zahldatenverarbeitung durch getasugar erfolgt. In diesem Fall ist der
          App-Store-Betreiber für die Abwicklung des Zahlungsprozesses selbst
          unabhängiger Verantwortlicher.
        </p>

        <h3>Weitergabe von Daten in Nicht-EWR-Länder</h3>

        <p>
          Ihre personenbezogenen Daten werden ggfs. auch an Dritte (mit uns
          gemeinsam oder allein) Verantwortliche, bzw. Auftragsverarbeiter, die
          ihren Sitz in Nicht- EU-/EWR-Ländern haben, weitergegeben. In diesem
          Fall stellen wir vor der Weitergabe sicher, dass beim Empfänger
          entweder ein angemessenes Datenschutzniveau besteht (z. B. aufgrund
          einer Angemessenheitsentscheidung der EU Kommission für das jeweilige
          Land gemäß Art. 45 DSGVO oder der Vereinbarung sogenannter
          Standardvertragsklauseln der Europäischen Union mit dem Empfänger
          gemäß Art. 46 DSGVO unter Einsatz zusätzlicher technischer und
          organisatorischer Schutzmaßnahmen) bzw. , dass eine hinreichende
          Einwilligung unserer Nutzer vorliegt.
        </p>

        <p>
          Sie können bei uns eine Übersicht über die konkreten Empfänger
          (Auftragsverarbeiter) in Drittstaaten und eine Kopie der konkret
          vereinbarten Regelungen zur Sicherstellung des angemessenen
          Datenschutzniveaus erhalten. Bitte nutzen Sie hierfür die Angaben im
          Abschnitt Kontakt.
        </p>
      </Grid>
      <Grid item xs={12}>
        <h2>Wie schützen wir Ihre Daten? </h2>
        <p>
          getasugar setzt verschiedene Sicherheitsmaßnahmen wie
          Verschlüsselungs- und Authentifizierungswerkzeuge nach dem aktuellen
          Stand der Technik ein, um die Sicherheit, Integrität und Verfügbarkeit
          der personenbezogenen Daten seiner Kunden und Nutzer zu schützen.
          Insbesondere handelt es sich um folgende Maßnahmen:
        </p>
        <ul>
          <li>
            Strenge Kriterien für die Berechtigung zum Zugriff auf Ihre Daten
            sowie 2-Faktor-Authentifizierung,
          </li>
          <li>Speicherung vertraulicher Daten in verschlüsselter Form,</li>
          <li>
            Firewall-Absicherung von IT-Systemen zum Schutz vor unbefugten
            Zugriffen,
          </li>
          <li>
            Permanente Überwachung der Zugriffe auf IT-Systeme zur Erkennung und
            Unterbindung einer missbräuchlichen Verwendung personenbezogener
            Daten.
          </li>
        </ul>
        <p>
          Wir empfehlen Ihnen in diesem Zusammenhang, sich mit den
          Sicherheitstipps von getasugar im Rahmen der Nutzung des
          getasugar-Service vertraut zu machen. Nähere Informationen zu diesem
          Thema finden Sie hier.
        </p>

        <p>
          getasugar setzt in diesem Zusammenhang auch Dienstleister aus den USA
          ein.
        </p>
      </Grid>
      <Grid item xs={12}>
        <h2>Dauer der Speicherung; Aufbewahrungspflichten</h2>
        <p>
          Wir speichern Ihre Daten so lange, wie dies zur Erbringung unseres
          Online-Angebots (Basis-Mitgliedschaft / VIP-Mitgliedschaft) und der
          damit verbundenen Services erforderlich ist bzw. wir ein berechtigtes
          Interesse an der weiteren Speicherung haben. In allen anderen Fällen
          löschen wir Ihre personenbezogenen Daten mit Ausnahme solcher Daten,
          die wir zur Erfüllung vertraglicher oder gesetzlicher (z. B. steuer-
          oder handelsrechtlicher) Aufbewahrungsfristen weiter vorhalten müssen
          (z. B. Rechnungen).
        </p>

        <p>
          Daten, die einer Aufbewahrungsfrist unterliegen, sperren wir bis zum
          Ablauf der Frist.
        </p>

        <p>
          Für Basis-Mitglieder gilt: Sie können die Speicherung der in Ihrem
          Profil enthaltenen personenbezogenen Daten jederzeit mit Wirkung für
          die Zukunft widerrufen. Von Basis-Mitgliedern, die 24 Monate inaktiv
          sind, löscht getasugar die personenbezogenen Profildaten
          automatisiert.
        </p>

        <p>
          Für VIP-Mitglieder gilt: Die personenbezogenen Daten von
          getasugar-VIP-Mitgliedern werden grundsätzlich für die Laufzeit des
          Vertragsverhältnisses gespeichert. Sollten Sie uns bis zum Ende der
          VIP-Mitgliedschaft nicht um Löschung Ihrer Daten bitten, wandelt sich
          Ihre VIP-Mitgliedschaft in eine Basis-Mitgliedschaft. Es gelten sodann
          die zuvor gemachten Angaben über die Dauer der Speicherung von
          Basis-Mitglieder-Daten.
        </p>

        <p>
          Die Deinstallation der App auf Ihrem Endgerät führt nicht zur Löschung
          der Daten in Ihrem Profil. Hierfür gelten die eben gemachten Aussagen
          über die Löschung von Daten von Basis-Mitgliedern oder
          VIP-Mitgliedern.
        </p>

        <p>
          Log-Dateien werden von getasugar für 30 Tage gespeichert und danach
          gelöscht. Log-Dateien, deren weitere Aufbewahrung zu Beweiszwecken
          erforderlich ist, sind von der Löschung ausgenommen, und zwar bis zur
          endgültigen Klärung des jeweiligen Vorfalls und können im Einzelfall
          an Ermittlungsbehörden weitergegeben werden.
        </p>

        <p>
          Sofern Sie uns darum bitten, löschen wir Ihre Daten, sofern für diese
          Daten keine gesetzliche Aufbewahrungspflicht besteht. Bitte beachten
          Sie, dass Ihre Daten zunächst nur gesperrt werden, sofern der Löschung
          Aufbewahrungspflichten entgegenstehen.
        </p>

        <p>
          Im Rahmen der Geltendmachung von Betroffenenrechten speichert
          getasugar diejenigen Daten, die für die Erfüllung oder den Nachweis
          während der jeweiligen Verjährungsfrist erforderlich sind.
        </p>

        <h3>
          Sind Sie verpflichtet, uns personenbezogene Daten bereitzustellen?
        </h3>

        <p>
          Grundsätzlich sind Sie nicht dazu verpflichtet, uns Ihre
          personenbezogenen Daten zur Verfügung zu stellen. Die Nutzung
          bestimmter Services unseres Online-Angebots kann allerdings die Angabe
          personenbezogener Daten erfordern, z. B. eine Registrierung oder die
          Teilnahme an einem Gewinnspiel. Wenn dies der Fall ist, weisen wir Sie
          gesondert darauf hin (etwa gegebenenfalls in Form von gesonderten
          Datenschutzhinweisen für etwaiges Gewinnspiel). Pflichtangaben sind
          regelmäßig mit einem * gekennzeichnet. Wenn Sie uns die hierfür
          erforderlichen Daten nicht zur Verfügung stellen möchten, können Sie
          diese Services leider nicht nutzen.
        </p>
      </Grid>

      <Grid item xs={12}>
        <h2>Betroffenenrechte im Überblick </h2>
        <h3>Wie können Sie Ihre Rechte geltend machen?</h3>
        <p>
          Bitte nutzen Sie zur Geltendmachung Ihrer Rechte die Angaben im
          Abschnitt Kontakt und ermöglichen uns bei der Geltendmachung eine
          eindeutige Identifizierung Ihrer Person.
        </p>
        <p>
          Für die Berichtigung Ihrer bei der Registrierung angegebenen Daten
          oder einen etwaigen Werbewiderspruch können Sie auch die
          Einstellungsmöglichkeiten in Ihrem getasugar-Profil verwenden. Hiervon
          ausgenommen sind einige wenige Angaben, wie das Geburtsdatum, das nur
          mit Hilfe des Kundenservices geändert werden kann.
        </p>
        <p>
          Bitte beachten Sie, dass Ihre Daten zunächst nur gesperrt werden,
          sofern der Löschung Aufbewahrungspflichten entgegenstehen.
        </p>
        <h3>Auskunftsanspruch und Recht auf Berichtigung</h3>
        <p>
          Sie haben das Recht, von getasugar Auskunft darüber zu verlangen, ob
          wir Sie betreffende personenbezogene Daten verarbeiten. Hierneben
          haben Sie auch das Recht auf Auskunft über diese personenbezogenen
          Daten sowie Angaben zu der Kategorie von Daten, die gespeichert
          werden, dem Verarbeitungszweck, etwaigen Empfängern, die Speicherdauer
          und die Ihnen zustehenden Betroffenenrechte. Sollten Ihre Daten
          unrichtig oder unvollständig sein, können Sie verlangen, dass Ihre
          Daten berichtigt bzw. vervollständigt werden. Wenn wir Ihre Daten an
          Dritte weitergegeben haben, informieren wir diese über die
          Berichtigung, soweit dies gesetzlich vorgeschrieben ist.
        </p>
        <p>
          Nutzen Sie bitte zur Geltendmachung Ihres Auskunftsanspruches die
          Angaben im Abschnitt Kontakt.
        </p>
        <h3>Ihr Recht auf Löschung</h3>
        <p>
          Sie können, wenn die gesetzlichen Voraussetzungen vorliegen, von uns
          unverzügliche Löschung Ihrer personenbezogenen Daten verlangen. Dies
          ist insbesondere der Fall, wenn
        </p>
        <ul>
          <li>
            Ihre personenbezogenen Daten für die Zwecke, für die sie erhoben
            wurden, nicht länger benötigt werden;
          </li>
          <li>
            die Rechtsgrundlage für die Verarbeitung ausschließlich Ihre
            Einwilligung war und Sie diese widerrufen haben;
          </li>
          <li>
            Sie der Verarbeitung zu werblichen Zwecken widersprochen haben
            („Werbewiderspruch”);
          </li>
          <li>
            Sie einer Verarbeitung auf der Basis der Rechtsgrundlage
            Interessenabwägung aus persönlichen Gründen widersprochen haben und
            wir nicht nachweisen können, dass es vorrangige berechtigte Gründe
            für eine Verarbeitung gibt;
          </li>
          <li>
            Ihre personenbezogenen Daten unrechtmäßig verarbeitet wurden; oder
          </li>
          <li>
            Ihre personenbezogenen Daten gelöscht werden müssen, um gesetzlichen
            Anforderungen zu entsprechen.
          </li>
        </ul>
        <p>
          Bitte beachten Sie, dass Ihr Recht auf Löschung Einschränkungen
          unterliegt.
        </p>
        <p>
          Wir haben das Recht, einem solchen Antrag nicht nachzukommen, wenn wir
          die personenbezogenen Daten aus folgenden Gründen verarbeiten:
        </p>
        <ul>
          <li>
            Zur Wahrnehmung einer Aufgabe im öffentlichen Interesse oder zur
            Ausübung öffentlicher Gewalt.
          </li>
          <li>
            Für Archivierungszwecke im öffentlichen Interesse, wissenschaftliche
            Forschung, historische Forschung oder statistische Zwecke.
          </li>
          <li>Zur Geltendmachung oder Verteidigung von Rechtsansprüchen.</li>
          <li>Für steuerrechtlich notwendige Dokumentationszwecke.</li>
        </ul>
        <h3>Ihr Recht auf Einschränkung der Verarbeitung</h3>
        <p>
          Sie können, wenn die gesetzlichen Voraussetzungen vorliegen, von uns
          eine Einschränkung der Verarbeitung verlangen. Dies ist insbesondere
          der Fall, wenn
        </p>
        <ul>
          <li>
            die Richtigkeit Ihrer personenbezogenen Daten von Ihnen bestritten
            wird, und dann solange bis wir die Möglichkeit hatten, die
            Richtigkeit zu überprüfen;
          </li>
          <li>
            die Verarbeitung nicht rechtmäßig erfolgt und Sie statt der Löschung
            (siehe hierzu den vorigen Abschnitt) eine Einschränkung der Nutzung
            verlangen;
          </li>
          <li>
            wir Ihre Daten nicht mehr für die Zwecke der Verarbeitung benötigen,
            Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung
            Ihrer Rechtsansprüche brauchen;
          </li>
          <li>
            Sie Widerspruch aus persönlichen Gründen erhoben haben, und dann
            solange bis feststeht, ob Ihre Interessen überwiegen.
          </li>
        </ul>
        <p>
          Alternativ können Sie sich zur Berichtigung Ihrer bei der
          Registrierung angegebenen Daten oder für Ihren Werbewiderspruch auch
          die Einstellungsmöglichkeiten in Ihrem Profil verwenden. Einige der
          über Sie erfassten Daten können nur mit Hilfe des Kundenservices
          geändert werden.
        </p>
        <h3>Ihr Recht auf Datenübertragbarkeit</h3>
        <p>
          Sie haben das Recht, personenbezogene Daten, die Sie uns zur
          Vertragserfüllung oder auf Basis einer Einwilligung gegeben haben, in
          einem übertragbaren Format zu erhalten. Sie können in diesem Fall auch
          verlangen, dass wir diese Daten direkt einem Dritten übermitteln,
          soweit dies technisch machbar ist.
        </p>
        <h3>Ihr Recht auf Widerruf einer Einwilligung</h3>
        <p>
          Sofern Sie uns eine Einwilligung in die Verarbeitung Ihrer Daten
          erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft
          widerrufen. Die Rechtmäßigkeit der Verarbeitung Ihrer Daten bis zum
          Widerruf bleibt hiervon unberührt. Sie haben uns Daten über Ihre
          sexuelle Orientierung (Geschlecht und gesuchtes Geschlecht) zur
          Verfügung gestellt. Sie können diese Einwilligung jederzeit mit
          Wirkung für die Zukunft widerrufen. Die Rechtmäßigkeit der
          Verarbeitung Ihrer Daten bis zum Widerruf bleibt hiervon unberührt. Ab
          dem Zeitpunkt Ihres Widerrufs der Verarbeitung Ihrer Daten kann
          getasugar seinen Service aufgrund Ihres Widerrufs nicht mehr wie in
          den AGB beschrieben erbringen.
        </p>
        <h3>Ihr Widerspruchsrecht gegen Direktmarketing</h3>
        <p>
          Sie können außerdem jederzeit Widerspruch gegen die Verarbeitung Ihrer
          personenbezogenen Daten zu werblichen Zwecken einlegen
          („Werbewiderspruch“). Bitte berücksichtigen Sie, dass es aus
          organisatorischen Gründen zu einer Überschneidung zwischen Ihrem
          Widerruf und der Nutzung Ihrer Daten im Rahmen einer bereits laufenden
          Kampagne kommen kann.
        </p>
        <h3>Ihr Widerspruchsrecht aus persönlichen Gründen</h3>
        <p>
          Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen
          Situation ergeben, der Datenverarbeitung durch uns zu widersprechen,
          soweit diese auf der Rechtsgrundlage berechtigtes Interesse beruht.
          Wir werden dann die Verarbeitung Ihrer Daten einstellen, es sei denn
          wir können – gemäß den gesetzlichen Vorgaben – zwingende schutzwürdige
          Gründe für die Weiterverarbeitung nachweisen, welche Ihre Rechte
          überwiegen.
        </p>
        <h3>Beschwerderecht bei der Aufsichtsbehörde</h3>
        <p>
          Sie haben das Recht, eine Beschwerde bei einer Datenschutzbehörde
          einzureichen. Sie können sich dazu an die Datenschutzbehörde wenden,
          die für Ihren Wohnort bzw. Ihr Bundesland zuständig ist oder an die
          für uns zuständige Datenschutzbehörde. Dies ist:
        </p>
        <p>
          Freie und Hansestadt Hamburg, Der Hamburgische Beauftragte für
          Datenschutz und Informationsfreiheit, E-Mail:
          mailbox@datenschutz.hamburg.de
        </p>
      </Grid>

      <Grid item xs={12}>
        <h2>Social Media</h2>
        <p>
          Grundsätzlich führt die Einbindung von Social Media Plugins regelmäßig
          dazu, dass die Anbieter der Plugins Cookies speichern. Die Social
          Media Buttons auf der getasugar-Plattform enthalten allerdings nur
          Text-Links auf die Seiten der jeweiligen sozialen Medien, da es sich
          nicht um sogenannte Social Media Plugins handelt. Von getasugar werden
          daher keine Daten an den jeweiligen Anbieter der sozialen Medien
          übertragen. Für die Einhaltung der Datenschutzvorschriften ist der
          Betreiber der Seite der sozialen Medien verantwortlich. Weitere
          Informationen erhalten Sie in den dort aufgeführten
          Datenschutzhinweisen.
        </p>
      </Grid>

      <Grid item xs={12}>
        <h2>Cookies- & Tracking-Hinweise</h2>
        <script
          id="CookieDeclaration"
          src="https://consent.cookiebot.com/2301178e-0527-4aff-893f-445b43a449f2/cd.js"
          type="text/javascript"
          async
        ></script>
      </Grid>
    </Grid>
  );
}
