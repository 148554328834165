import { createStyles, makeStyles, useTheme } from '@mui/styles';
import { Theme, styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { Grid } from '@mui/material';
import VIPBadge from '../atoms/UserVIPBadge';
import { successColor } from '../styles/base';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageFullWidth: {
      width: '100%',
      height: '100%',
      margin: '0 auto',
      cursor: 'pointer',
    },
    ratioBox: {
      position: 'absolute',
      top: 0,
      width: '100%',
    },
    textPadding: {
      padding: '1em',
      [theme.breakpoints.down('sm')]: {
        padding: '0.5em',
      },
    },
    userNameWrapper: {
      whiteSpace: 'nowrap',
      display: 'inline-block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 'bold',
    },
    chip: {
      marginRight: '0.3em',
      marginTop: '0.3em',
      marginBottom: '0.3em',
      borderRadius: '3px !important',
    },
  })
);

const OnlineCircle = styled('span', {
  name: 'OnlineCircle', // The component name
})(({ theme }) => ({
  height: '10px',
  width: '10px',
  lineHeight: '1em',
  backgroundColor: successColor,
  display: 'inline-block',
  borderRadius: '50%',
  marginLeft: '0px',
  marginRight: '10px',
}));

const RatioContainer = styled(Box, {
  name: 'RatioContainer', // The component name
})(({ theme }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: '100%',
  overflow: 'hidden',
}));

export enum UserCartDisplayMode {
  full = 'full',
  small = 'small',
  tiny = 'tiny',
}

interface Item {
  picture: string;
  age: number;
  name: string;
  location: string;
  isOnline?: boolean;
}

interface Props {
  mode?: UserCartDisplayMode;
  onClick(): void;
  item: Item;
  additionalInfo?: number;
  additionalInfoIcon?: any;
  isNew?: boolean;
}

export function DummyUserCard(props: Props) {
  const {
    item,
    mode = UserCartDisplayMode.full,
    additionalInfo,
    additionalInfoIcon,
    isNew,
    onClick,
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  return (
    <Paper elevation={2} style={{ margin: '0.25em', cursor: 'pointer' }}>
      <RatioContainer onClick={onClick}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
          }}
          zIndex="0"
        >
          <img
            src={'/tiles/' + item.picture}
            alt=""
            style={{
              backgroundImage: `url("/tiles/${item.picture}")`,
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
              width: '100%',
              height: '100%',
              position: 'relative',
              backgroundSize: 'cover',
            }}
          />
        </Box>

        {isNew && (
          <Box
            position="absolute"
            zIndex="1"
            top="0"
            left="0"
            style={{ margin: '5px' }}
          >
            <Chip
              color="primary"
              size="small"
              label="NEU"
              className={classes.chip}
            />
          </Box>
        )}

        {additionalInfo && (
          <Box
            position="absolute"
            zIndex="1"
            top="0"
            right="0"
            style={{ margin: '5px', marginRight: '2px' }}
          >
            <Chip
              icon={additionalInfoIcon ? additionalInfoIcon : ''}
              color="primary"
              size="small"
              label={additionalInfo}
              className={classes.chip}
            />
          </Box>
        )}
      </RatioContainer>
      <div
        className={classes.textPadding}
        style={{ cursor: 'pointer' }}
        onClick={onClick}
      >
        <Box
          display="flex"
          justifyContent="start"
          justifyItems="center"
          flexDirection="row"
          style={{ gap: '0.5em' }}
        >
          <Typography
            variant="body1"
            component={Box}
            className={classes.userNameWrapper}
          >
            {item.name}
          </Typography>
          {/*!UserCartDisplayMode.full && (
            <Box display="flex" alignItems="center">
              <UserOnlineState
                user={user}
                showOffline={false}
                lastOnline={user.lastOnline}
              />
            </Box>
          )*/}

          {mode === UserCartDisplayMode.full && (
            <Box
              display="flex"
              justifyContent="start"
              justifyItems="center"
              flexDirection="row"
              style={{ gap: '0.5em' }}
            >
              <Typography variant="body1" component={Box}>
                {item.age}
              </Typography>
              <Box>{false && <VIPBadge />}</Box>
              {item.isOnline && (
                <Box display="flex" alignItems="center">
                  <span>
                    <OnlineCircle />
                  </span>
                </Box>
              )}
            </Box>
          )}
        </Box>

        <Grid container wrap="nowrap">
          <Grid item zeroMinWidth>
            <Typography
              variant="body2"
              noWrap
              style={{
                marginTop: mode === UserCartDisplayMode.full ? '0.5em' : 0,
              }}
            >
              {item.location}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
