'use client'
import { FC, useState } from "react";
import { useRouter } from "next/navigation";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";

// components

import { TypeSelect } from "./TypeSelect";

//styles

import styles from "../styles/badgeStyle";
import { Grid } from "@mui/material";

import {Button} from "../atoms/Button";
import { successColor, whiteColor } from "../styles/base";
import {styles as commonStyles} from "../styles/common";
import { ProfileType } from "@sugar/models";

const useStyles = makeStyles({
  ...commonStyles,
  ...styles,
  isWhite: {
    color: whiteColor + " !important",
    "& *": {
      color: whiteColor + " !important",
    },
  },
  padding: {
    paddingTop: 20,
    paddingBottom: 20,
  }
});

export const CTABlock: FC = () => {
  const classes = useStyles();
  const router = useRouter();

  const [type, setType] = useState<ProfileType>("0");

  return (
    <div
      className={classNames(classes.hasBorderRadius, classes.padding)}
    >
      <div className={classes.container}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-around"
          alignContent="center"
        >
          <Grid item xs={12} sm={4}>
            <div className={classes.isWhite}>
              <TypeSelect setType={setType} type={type} variant="highlighted" />
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              style={{ backgroundColor: successColor }}
              fullWidth
              onClick={() => router.push("/register?type=" + type)}
            >
              jetzt kostenlos flirten
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
