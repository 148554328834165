'use client';

import { FC } from 'react';
import { styles } from '../styles/common';
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  ...styles,

  wrapper: {
    height: '500px',
    marginTop: '70px',
    width: '100%',
    display: 'grid',
    placeItems: 'center',
    background: 'linear-gradient(0deg, #ffffff 0%,#cc9eff 50%,#b571ff 100%);',
    color: 'black',
  },

  formRoot: {
    padding: '20px 60px',

    display: 'grid',
    placeItems: 'center',
    background: 'white',
  },
});

export const SugababesHero: FC = () => {
  const classes = useStyles();

  const theme = useTheme();
  return (
    <div className={classes.wrapper}>
      <div className={classes.formRoot}>
        <h1>Sugababes Hero</h1>

        <div>
          <input type="radio" id="hero1" name="name" value="1" />
          <label htmlFor="hero1">Option 1</label>
        </div>

        <div>
          <input type="radio" id="hero2" name="name" value="2" />
          <label htmlFor="hero2">option 2</label>
        </div>
      </div>
    </div>
  );
};
