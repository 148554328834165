import { createStyles } from '@mui/styles'
import { blackColor, primaryColor, primaryColor50, whiteColor } from './base';
import { styled } from '@mui/material';

const conatinerFluid = {
  paddingRight: '15px',
  paddingLeft: '15px',
  marginRight: 'auto',
  marginLeft: 'auto',
  width: '100%',
};
export const container = {
  ...conatinerFluid,
  '@media (min-width: 576px)': {
    maxWidth: '540px',
  },
  '@media (min-width: 768px)': {
    maxWidth: '720px',
  },
  '@media (min-width: 992px)': {
    maxWidth: '960px',
  },
  '@media (min-width: 1200px)': {
    maxWidth: '1140px',
  },
};

export const PrimaryContainer = styled('div', {
  name: 'PrimaryContainer', // The component name
})(({ theme }) => ({
  backgroundColor: primaryColor,
  background: "linear-gradient(45deg, #542687 0%,#744BA1 50%,#542687 100%)",
  color: whiteColor
}))

export const BoundingContainer = styled('div', {
  name: 'BoundingContainer', // The component name
})(({ theme }) => ({
  ...container,
}));

export const styles = createStyles({
  hasBorderRadius: {
    borderRadius: 5,
  },
	hasTextCentered: {
    textAlign: "center",
  },
	signUpBox: {
    backgroundColor: "rgba(255,255,255,0.92)",
    color: blackColor + " !important",
    padding: "1em",
    borderRadius: "6px",
    marginTop: "0",
  },
	hasShadow: {
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.15)",
  },
  container,

  isPrimary: {
    color: primaryColor + "!important",
    "&hover": {
      color: primaryColor50,
    },
  },
  isPrimary50: {
    backgroundColor: primaryColor50,
  },
  isBlack: {
    color: blackColor + " !important",
  },
  isWhite: {
    color: whiteColor + " !important",
    "& *": {
      color: whiteColor + " !important",
    },
  },
  textCenter: {
    textAlign: 'center',
    justifyContent: 'center !important',
  },
  rowOdd: {
    backgroundColor: primaryColor,
    background: "linear-gradient(45deg, #542687 0%,#744BA1 50%,#542687 100%)",
    color: whiteColor
  },
  isBold: {
    fontWeight: 600,
  },
})
