'use client'
import { Person } from '@mui/icons-material';
import { FormControl, IconButton, InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {styles as formStyle} from '../styles/formStyle'

const useStyles = makeStyles({
  ...formStyle,
  formControl: {
    marginTop: '1px',
  },
});

interface ValueWithMessage {
  value: string,
  valid: boolean,
  errorMsg?: string
}

interface Props {
  userName: ValueWithMessage
  error: string
  placeHolder?: string
  setError(error: string): void
  validateUserName(name: string): void
}

export const RegisterUserName = (props: Props) => {
  const classes = useStyles();

  const { userName, error, setError, validateUserName, placeHolder } = props

  return (
    <FormControl fullWidth margin="dense" className={classes.formControl}>
      <TextField
        variant="standard"
        error={!userName.valid || error === 'userNameTaken'}
        helperText={
          !userName.valid
            ? userName.errorMsg
            : error === 'userNameTaken'
            ? 'Der Name ist bereits registiert'
            : error === 'userNameInvalid'
            ? 'Erlaubte Zeichen sind Buchstaben, Zahlen, Leerzeichen sowie ,-'
            : 'Dein echter Vorname macht dich authentisch, Pseudonyme werden aber ebenfalls akzeptiert'
        }
        id="userName"
        label={placeHolder || "Gewünschter Name"}
        fullWidth
        value={userName.value}
        onChange={(e) => {
          if (['userNameTaken', 'userNameValid'].includes(error)) {
            setError('');
          }
          validateUserName(e.target.value);
        }}
        InputProps={{
          classes: {
            input: classes.input,
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <Person />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};
