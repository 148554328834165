import { createStyles } from "@mui/styles";
import { grayColor } from "./base";

export const styles = createStyles({
  footer: {
    fontSize: "0.8em !important",
    color: grayColor,
  },

  footerList: {
    display: "inline-block",
  },

  footerListItem: {
    display: "inline",
    textAlign: "center",

    padding: "2px 8px",
    paddingRight: "0",
  },

  footerLink: {
    color: grayColor,
    textTransform: "uppercase",
    fontWeight: 500,
    "&:hover": {
      color: grayColor,
      textDecoration: "underline",
    },
  },
})