import classNames from 'classnames'
// @mui/material components
import { makeStyles } from '@mui/styles'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Badge from '@mui/material/Badge'

import { Notifications, Mail, Event, Home, Person } from '@mui/icons-material'
import { Theme } from '@mui/material/styles'

import HeaderLinks from './DummyRegisterHeaderLinks'
import logo from 'assets/getasugarLogo.svg'
import { primaryColor, successColor, whiteColor } from '../styles/base'
import { styles } from '../styles/common'

const containerFluid = {
  margin: '0 auto',
  width: '100%',
  maxWidth: '1140px !important',
  paddingLeft: '1.5em',
  paddingRight: '1.5em',
}
const container = {
  ...containerFluid,

  '@media (min-width: 576px)': {
    width: 'calc(100% - 24px)',
  },
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    ...styles,
    vipButton: {
      padding: '5px',
      paddingLeft: '10px',
      paddingRight: '10px',
      fontSize: '15px',
      backgroundColor: successColor,
      '&:hover': {
        backgroundColor: successColor,
      },
    },
    appBar: {
      display: 'flex',
      border: '0',
      borderRadius: '0px',
      padding: '0.625rem 0',
      marginRight: '0',
      marginTop: '0',
      color: whiteColor,
      width: '100%',
      backgroundColor: primaryColor[0],
  
      transition: 'all 150ms ease 0s',
      alignItems: 'center',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      position: 'relative',
    },
    mobileBottomAppBar: {
      display: 'flex',
      border: '0',
      marginRight: '0',
      color: whiteColor,
      backgroundColor: primaryColor[0],
      alignItems: 'center',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      position: 'relative',
      padding: '1em 0em 0.5em 0em',
      width: '100%',
      borderBottomRightRadius: '6px',
      borderBottomLeftRadius: '6px',
      marginTop: '2em',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '20px',
        paddingBottom: 'calc(env(safe-area-inset-bottom) + 10px)',
        borderRadius: '0',
        borderBottomRightRadius: '0',
        borderBottomLeftRadius: '0',
        marginTop: '0',
        position: 'fixed',
        top: 'auto',
        left: 0,
        bottom: 0,
        right: 0,
      },
    },
    headerContainer: {
      ...container,
      minHeight: '50px',
      alignItems: 'center',
      justifyContent: 'space-between',
      display: 'flex',
      flexWrap: 'nowrap',
      margin: '0 auto',
      padding: 0,
    },
    fixed: {
      position: 'fixed',
      zIndex: 1100,
    },
    primary: {
      backgroundColor: primaryColor,
      color: '#FFFFFF',
      boxShadow:
        '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46)',
    },
  }
})

interface Props {
  onClick(): void
}

export const DummyRegisterHeader = (props: Props) => {
  const classes = useStyles()
  const { onClick } = props
  //const { color, links, fixed, absolute } = props

  const iconNavItems = [
    {
      label: 'Neues',
      path: '/notifications',
      icon: <Notifications />,
      display: { xs: 'block', sm: 'block' },
      count: 0,
    },
    {
      label: 'Nachrichten',
      path: '/messenger',
      icon: <Mail />,
      display: { xs: 'block', sm: 'block' },
      count: 0,
    },
    {
      label: 'Home',
      route: () => ({
        pathname: "/discover",
        state: {
          searchArgs: {
            seed: Math.random()
          }
        }
      }),
      icon: <Home style={{ width: '40px', height: '40px' }} />,
      display: { xs: 'block', sm: 'block', md: 'none' },
      count: 0,
    },
    {
      label: 'Events',
      path: '/events/discover',
      icon: <Event />,
      display: { xs: 'block', sm: 'block' },
      count: 0,
    },
    {
      label: 'Profil',
      path: '/settings/profile',
      icon: <Person />,
      display: { xs: 'block', sm: 'block', md: 'none' },
      count: 0,
    },
  ]

  const iconNavDesktop = iconNavItems.map((item, key) => {
    return (
      <Box display={item.display} sx={{marginRight: '1em'}} key={key} onClick={() => onClick()}>
        <span
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <Badge badgeContent={item.count} color="secondary" invisible={item.count === 0} max={99}>
            {item.icon}
          </Badge>

          <div style={{ paddingLeft: '0.5em' }}>{item.label}</div>
        </span>
      </Box>
    )
  })

  //TODO: check active routes

  const iconNavMobile = iconNavItems.map((item, key) => {
    return (
      <Box
        component={Grid}
        display={item.display}
        key={key}
        sx={{
                borderBottom: '2px solid transparent',
                width: '5.2em',
                marginBottom: '0.1em',
                cursor: 'pointer'
              }
        }
      >
        <span className={classes.isWhite}>
          {item.label === 'Home' ? (
            <div
              style={{
                borderRadius: '50%',
                width: '5.2em',
                height: '5.2em',

                background: primaryColor[0],
                padding: '0.75em',
                marginTop: '-2.3em',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                justifyItems: 'center',
                boxShadow: '0 -5px 10px 0 rgba(51,51,51,0.25)',
              }}
            >
              <Badge
                badgeContent={item.count}
                color="secondary"
                invisible={item.count === 0}
                max={99}
              >
                {item.icon}
              </Badge>

              <div style={{ fontSize: '0.75em', marginTop: '-0.2em' }}>{item.label}</div>
            </div>
          ) : (
            <div className={classes.textCenter}>
              <Badge
                badgeContent={item.count}
                color="secondary"
                invisible={item.count === 0}
                max={99}
              >
                {item.icon}
              </Badge>

              <div style={{ fontSize: '0.75em' }}>{item.label}</div>
            </div>
          )}
        </span>
      </Box>
    )
  })

  return (
    <div>
      <AppBar className={classNames(classes.appBar, classes.fixed, classes.primary)}>
        <Toolbar className={classes.headerContainer}>
          <Box
            component="div"
            display={{ xs: 'block', sm: 'inline' }}
            className={classes.textCenter}
            onClick={() => onClick()}
            sx={{
              cursor: 'pointer'
            }}
          >
            <span>
              <img
                src={(logo as any).src}
                alt="getasugar.de"
                style={{ width: 'calc(100vw * 0.30)', maxWidth: '160px' }}
              />
            </span>
          </Box>

          <Box display={{ xs: 'none', sm: 'none', md: 'flex', lg: 'flex' }}
            sx={{
              justifyContent: 'flext-start'
            }}
          >
            {iconNavDesktop}
          </Box>
          <Box display={{ xs: 'inline', sm: 'inline' }}>
            <HeaderLinks onClick={onClick} />
          </Box>
        </Toolbar>
      </AppBar>
      {/*<Box display={{ xs: 'block', sm: 'block', md: 'none' }}>
        <AppBar
          component="footer"
          className={classNames(
            classes.fixed,
            classes.primary,
            classes.mobileBottomAppBar
          )}
        >
          <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
            {iconNavMobile}
          </Grid>
        </AppBar>
          </Box>*/}
    </div>
  )
}
