'use client'
import { createTheme } from '@mui/material/styles';
import { blackColor, grayColor, primaryColorArray, secondaryColor, whiteColor } from '../styles/colors';

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: primaryColorArray[0],
      contrastText: secondaryColor,

    },
    secondary: { main: primaryColorArray[2], contrastText: primaryColorArray[1] }
  },

  typography: {
    fontFamily: ['"Fira Sans"', '"Helvetica"', '"sans-serif"'].join(','),

    subtitle1: {
      fontSize: '14px',
      fontWeight: 300,
      letterSpacing: 'normal',
      marginTop: '0.5em',
      marginBottom: '0.5em',
      color: grayColor[1],
    },

    subtitle2: {
      fontWeight: 400,
      marginTop: '1em',
      marginBottom: '0.5em',
      fontSize: '17px',
      //textTransform: 'uppercase',

    },

    h1: {
      fontSize: '2em',
      fontWeight: 700,
      marginBottom: '0.75em',
      marginTop: '0.25em'
    },

    h2: {
      fontSize: '1.8em',
      fontWeight: 700,
      marginBottom: '0.75em',
      marginTop: '0.25em',
    },

    h3: {
      fontSize: '22px',
      fontWeight: 700,
      marginBottom: '0.75em',
      marginTop: '1.5em',
    },

    h4: {
      fontSize: '18px',
      fontWeight: 600,
      marginBottom: '0.5em',
      marginTop: '1em',
      textAlign: 'center'
    },

    h6: {
      fontSize: '18px',
      fontWeight: 600,
    },

    body1: {
      color: blackColor,
      fontSize: '0.875rem',
      italic: {
        fontStyle: 'italic',
      },
    },
    body2: {
      fontSize: '0.875rem',

      //color: blackColor + '!important',
      //marginTop: '1em',
    },
    button: {
      color: whiteColor
    }
  },

  components: {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '18px !important',
          fontWeight: 600,
        },
  
      }
    },
    MuiList: {
      styleOverrides: {
        padding: {
          padding: 0,
        },
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto !important',
          marginRight: '1em',
        },
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: '0.05em',
          paddingBottom: '0.05em',
          '&$selected': {
            borderRight: '5px solid' + primaryColorArray[0],
          },
        },
        gutters: {
          paddingLeft: '0 !important',
        },
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '4px 8px !important',
        },
      }
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          //margin: '0.5em',
          textShadow: 'none !important',
        },
        dot: {
          border: '2px solid' + whiteColor,
          marginRight: '6px',
          marginBottom: '6px',
          minHeight: '12px',
          minWidth: '12px',
          borderRadius: '6px',
        },
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
        },
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          margin: '0.25em',
        },
      }
    },
  },
})
