import Link from 'next/link';
// @mui/material components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

// core components
import logo from 'assets/getasugarLogo.svg';
import { primaryColor } from '../styles/base';
import { styled } from '@mui/material';

const StyledToolbar = styled(Toolbar, {
  name: 'Toolbar', // The component name
  slot: 'root', // The slot name
})(({ theme }) => ({
  paddingRight: '15px',
  paddingLeft: '15px',
  marginRight: 'auto',
  marginLeft: 'auto',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  height: '56px',
  flex: '1',
  alignItems: 'center',
  flexWrap: 'nowrap',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '540px',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '720px',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '960px',
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: '1140px',
  },
}));

const StyledAppbar = styled(AppBar, {
  name: 'AppBar', // The component name
  slot: 'root', // The slot name
})(({ theme }) => ({
  display: 'flex',
  border: '0',
  borderRadius: '0',
  padding: '0.625rem 0',
  marginBottom: '20px',
  width: '100%',
  background: 'linear-gradient(45deg, #542687 0%,#744BA1 50%,#542687 100%)',
  transition: 'all 150ms ease 0s',
  alignItems: 'center',
  flexFlow: 'row nowrap',
  justifyContent: 'flex-start',
  backgroundColor: primaryColor,
  color: '#FFFFFF',
  boxShadow:
    '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46)',
  position: 'fixed',
  zIndex: 1100,
}));

interface Props {
  rightLinks?: any[];
  fixed?: boolean;
  logoClickable?: boolean
}

export function Header(props: Props) {
  const { rightLinks = [], logoClickable = true} = props;

  return (
    <StyledAppbar>
      <StyledToolbar>
        {logoClickable && (<Link href="/" as="/">
          <img src={(logo as any).src} width="160" alt="getasugar" />
        </Link>)}
        {!logoClickable && <img src={(logo as any).src} width="160" alt="getasugar" />}
        {rightLinks}
      </StyledToolbar>
    </StyledAppbar>
  );
}
