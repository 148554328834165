// core components
import { Grid, Link, Typography } from '@mui/material';
import address from 'assets/images/legal/imprintAddress.png';
import contact from 'assets/images/legal/imprintContact.png';

const params = {
  title: 'Impressum',
  teaser: '',
};

export function Imprint() {

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h1" component="h1">
          Impressum
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <p>getasugar ist ein Service der</p>
        <p>
          <img src={address.src} alt="Adresse" width="393" />
        </p>
        <p>
          Sitz der Gesellschaft: Hamburg
          <br />
          Amtsgericht Hamburg, HRB 178854
          <br />
          USt - IdNr: DE360485626
          <br />
        </p>

        <p>
          E-Mail:{' '}
          <Link href="mailto:getasugar@lovelyvibes.de">getasugar@lovelyvibes.de</Link>{' '}
          <br />
          Telefon: +49 40 / 33 46 46 26 <br />
        </p>
      </Grid>
      <Grid item xs={12}>
        <h3>Alternative Streitschlichtung (Art. 14 Abs. 1 ODR-VO):</h3>
        <p>
          Die Internetplattform der Europäischen Union zur
          Online-Streitbeilegung (sog. "OS-Plattform") ist unter dem folgenden
          Link erreichbar:
          <Link href="http://ec.europa.eu/consumers/odr/">
            http://ec.europa.eu/consumers/odr/
          </Link>
        </p>
        <p>
          lovely vibes GmbH weist darauf hin, dass sie nicht bereit und nicht
          verpflichtet ist, an Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle teilzunehmen.
        </p>
      </Grid>
      <Grid item xs={12}>
        <h3>Haftungshinweis: </h3>
        <p>
          Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung
          für die Inhalte externer Links. Für den Inhalt verlinkter Seiten sind
          ausschließlich deren Betreiber verantwortlich.
        </p>
      </Grid>
      <Grid item xs={12}>
        <h3>Verantwortlicher im Sinne von § 18 Abs. 2 MStV: </h3>
        <p>
          <img src={contact.src} alt="Kontakt" width="116" />
        </p>
      </Grid>
    </Grid>
  );
}
